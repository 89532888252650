import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const blink = keyframes`
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

const LoadingDots = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <Box
      sx={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: 'white',
        animation: `${blink} 1s infinite`,
        animationDelay: '0s',
        mx: '2px',
      }}
    />
    <Box
      sx={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: 'white',
        animation: `${blink} 1s infinite`,
        animationDelay: '0.2s',
        mx: '2px',
      }}
    />
    <Box
      sx={{
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: 'white',
        animation: `${blink} 1s infinite`,
        animationDelay: '0.4s',
        mx: '2px',
      }}
    />
  </Box>
);

export default LoadingDots;





