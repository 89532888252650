import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Grid, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, useMediaQuery, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import pixelsImage from '../assets/pixels.png'; // Ajuste la ruta de la imagen

const TopRojanelos = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState({ table: [], participants: 0, participantsFree: 0 });
  const [searchTerm, setSearchTerm] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  const fetchData = async () => {
    try {
      const response = await fetch('https://www.pixels-tools-server.somee.com/SkillsLeaderboard');
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 30000); // Actualiza cada 30 segundos

    return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
  }, []);

  const formatNumber = (number) => {
    return new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const formatTime = (time) => {
    return `${time.days} DAYS ${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`;
  };

  const calculateTimeLeft = (targetDate) => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeftStart, setTimeLeftStart] = useState(calculateTimeLeft('2024-07-30T00:00:00Z'));
  const [timeLeftEnd, setTimeLeftEnd] = useState(calculateTimeLeft(new Date(new Date('2024-07-30T00:00:00Z').getTime() + 14 * 24 * 60 * 60 * 1000)));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeftStart(calculateTimeLeft('2024-07-30T00:00:00Z'));
      setTimeLeftEnd(calculateTimeLeft(new Date(new Date('2024-07-30T00:00:00Z').getTime() + 14 * 24 * 60 * 60 * 1000)));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleNotificationClose = () => {
    setNotification({ open: false, message: '', severity: 'success' });
  };

  const { table, participants, participantsFree } = data;
  const filteredData = table.filter(row => row.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ 
            background: 'linear-gradient(270deg, rgba(128, 0, 128, 1) 0%, #121212 100%)', 
            color: 'white', 
            width: '100%',
            height: '48px', // Ajuste de altura para un solo renglón, igual a los botones del menú acordeón
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 16px', // Agregar padding para separar del borde
          }}>
            <CardContent sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 !important' }}>
              <Typography variant="h6" sx={{ textTransform: 'uppercase', marginRight: 2, fontWeight: 'bold' }}>Total Participants</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{participants}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ 
            background: 'linear-gradient(90deg, rgba(128, 0, 128, 1) 0%, #121212 100%)', 
            color: 'white', 
            width: '100%',
            height: '48px', // Ajuste de altura para un solo renglón, igual a los botones del menú acordeón
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 16px', // Agregar padding para separar del borde
          }}>
            <CardContent sx={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 !important' }}>
              <Typography variant="h6" sx={{ textTransform: 'uppercase', marginRight: 2, fontWeight: 'bold' }}>Free Participants</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{participantsFree}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Card sx={{ 
          background: 'linear-gradient(270deg, #000000 0%, rgba(128, 0, 128, 1) 50%, #000000 100%)', 
          color: 'white', 
          width: '100%', 
          textAlign: 'center',
          padding: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '16px', // Ajustar padding para separar del borde
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2 }}>
            <Box>
              <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Starts In:</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{formatTime(timeLeftStart)}</Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>Ends In:</Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{formatTime(timeLeftEnd)}</Typography>
            </Box>
          </Box>
          <img src={pixelsImage} alt="Pixels" style={{ width: '20%', height: 'auto' }} />
        </Card>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Card sx={{ mb: 2, p: 2, bgcolor: '#1d1d1d', color: '#ffffff' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              input: { color: '#ffffff' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#90caf9',
                },
                '&:hover fieldset': {
                  borderColor: '#64b5f6',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#42a5f5',
                },
              },
            }}
          />
        </Card>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        {isMobile ? (
          <Box sx={{ marginTop: 2 }}>
            {filteredData.map((row) => (
              <Box key={row.id_} sx={{ marginBottom: 2, backgroundColor: '#1d1d1d', padding: 2, borderRadius: 1 }}>
                <Typography variant="body1" sx={{ color: 'white' }}><strong>TOP:</strong> {row.id_}</Typography>
                <Typography variant="body1" sx={{ color: 'white' }}><strong>USERNAME:</strong> {row.name}</Typography>
                <Typography variant="body1" sx={{ color: 'white' }}><strong>TOTAL EXP:</strong> {formatNumber(row.correntValueLeader)}</Typography>
                <Typography variant="body1" sx={{ color: 'white' }}><strong>EXP/HOUR:</strong> {formatNumber(row.promedio)}</Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <TableContainer component={Paper} sx={{ backgroundColor: '#1d1d1d', color: 'white', marginTop: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: 'linear-gradient(90deg, rgba(16, 20, 27, 0.043) 0%, rgba(128, 0, 128, 0.706) 11.11%, rgba(128, 0, 128, 0.792) 22.22%, rgba(128, 0, 128, 0.8) 33.33%, rgba(128, 0, 128, 0.776) 44.44%, rgba(128, 0, 128, 0.741) 55.56%, rgba(128, 0, 128, 0.729) 66.67%, rgba(128, 0, 128, 0.741) 77.78%, rgba(128, 0, 128, 0.722) 88.89%, rgba(27, 36, 53, 0.122) 100%)' }}>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', textTransform: 'uppercase' }}>TOP</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', textTransform: 'uppercase' }}>USERNAME</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', textTransform: 'uppercase' }}>TOTAL EXP</TableCell>
                  <TableCell sx={{ color: 'white', fontWeight: 'bold', textTransform: 'uppercase' }}>EXP/HOUR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((row) => (
                  <TableRow
                    key={row.id_}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ color: 'white' }}>
                      {row.id_}
                    </TableCell>
                    <TableCell sx={{ color: 'white' }}>{row.name}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{formatNumber(row.correntValueLeader)}</TableCell>
                    <TableCell sx={{ color: 'white' }}>{formatNumber(row.promedio)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TopRojanelos;

















