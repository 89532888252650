import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, LinearProgress, TextField, List, ListItem, useMediaQuery, InputAdornment, IconButton, Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';
import config from '../config';
import webSocketService from '../../websocket';
import { useGlobalState, useGlobalStateDispatch } from '../../GlobalStateContext';

const Selling = () => {
  const { operationNumber } = useParams();
  const navigate = useNavigate();
  const globalState = useGlobalState();
  const globalDispatch = useGlobalStateDispatch();
  const user = globalState.user;
  const userId = user ? user._id : '';
  const username = user ? user.username : '';
  const address = user ? user.addresses[0] : '';
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(100);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [status, setStatus] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openReleaseUSDDialog, setOpenReleaseUSDDialog] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chatEndRef = useRef(null);
  const isConnectingRef = useRef(false);

  const handleClickButton = () => {
    handleOpenConfirmationDialog();
  };


  const handleReleaseUSDDialog = () => {
    setOpenReleaseUSDDialog(true);
  };

const handleCloseReleaseUSDDialog = async (confirm) => {
  setOpenReleaseUSDDialog(false); 
  if (confirm) {
    await handleReleaseUSD();
  }
};

  useEffect(() => {
    const fetchOrderInfo = async () => {
      const token = localStorage.getItem('authToken');

      try {
        const response = await fetch(`${config.API_BASE_URL}/Order/Info?operationNumber=${operationNumber}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setOrderInfo(data);
          setLoading(false);
          setStatus(data.order.status);

          const joinTime = new Date(data.joinTime);
          const endTime = new Date(joinTime.getTime() + 15 * 60000);
          const now = new Date();

          const totalTime = endTime - joinTime;
          const timeElapsed = now - joinTime;
          const initialProgress = Math.max(0, ((totalTime - timeElapsed) / totalTime) * 100);
          setProgress(initialProgress);

          const interval = setInterval(() => {
            const now = new Date();
            const timeElapsed = now - joinTime;
            const currentProgress = Math.max(0, ((totalTime - timeElapsed) / totalTime) * 100);
            setProgress(currentProgress);
            if (currentProgress === 0) clearInterval(interval);
          }, 1000);

          return () => clearInterval(interval);
        } else {
          setError('Error fetching order information');
        }
      } catch (err) {
        setError('Error fetching order information');
      }
    };

    fetchOrderInfo();
  }, [operationNumber]);

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };
  
  const handleCloseConfirmationDialog = (confirm) => {
    setOpenConfirmationDialog(false);
    if (confirm) {
      handleTransactionCompleted();
    }
  };

  const connectToChat = async () => {
    const token = localStorage.getItem('authToken');
    if (!token || !userId || !username) {
      console.log('Waiting for token, userId, and username...');
      return;
    }

    if (isConnectingRef.current) {
      console.log('Already attempting to connect to SignalR');
      return;
    }

    isConnectingRef.current = true;

    try {
      await webSocketService.startConnection(token);
      console.log('Connected to SignalR');

      webSocketService.off('Chat');
      webSocketService.on('Chat', (message) => {
        setChatMessages((prevMessages) => {
          let newMessages;
          if (Array.isArray(message)) {
            newMessages = message.filter(msg => !msg.message.includes('snapshot') && !msg.message.includes('prossesing') && !msg.message.includes('se conecto')).map(msg => {
              if (msg.message.includes('open') || msg.message.includes('closed')) {
                setOpenDialog(true);
              }
              if (msg.message.includes('verified')) {
                return { ...msg, message: 'Coins have been transferred' };
              }
              return msg;
            });
          } else {
            newMessages = [...prevMessages, message].map(msg => {
              if (msg.message.includes('open') || msg.message.includes('closed')) {
                setOpenDialog(true);
              }
              if (msg.message.includes('verified')) {
                return { ...msg, message: 'Coins have been transferred' };
              }
              return msg;
            });
          }
          return newMessages;
        });

        if (message.username === 'Server' && ['open', 'prossesing', 'verified', 'closed'].includes(message.message)) {
          setStatus(message.message);
        }

        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      });

      setChatMessages([]);

      const response = await webSocketService.invoke('AddRoom', parseInt(operationNumber, 10), userId, username);
      if (response) {
        setChatMessages(response);
      }
    } catch (error) {
      console.error('Error invoking AddRoom:', error);
    } finally {
      isConnectingRef.current = false;
    }
  };

  useEffect(() => {
    connectToChat();
  }, [operationNumber, userId, username]);

  const handleSendMessage = async () => {
    if (!userId || !username || !newMessage.trim()) {
      console.error('Missing user ID, username, or message is empty');
      return;
    }

    try {
      await webSocketService.invoke('SendMessage', parseInt(operationNumber, 10), userId, username, newMessage);
      setNewMessage('');
    } catch (error) {
      console.error('Error invoking SendMessage:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const handleDispute = async () => {
    const confirmDispute = window.confirm("Do you want to start a dispute?");
    
    if (confirmDispute) {
      const token = localStorage.getItem('authToken');
      if (!token) {
        return;
      }
  
      try {
        const response = await fetch(`${config.API_BASE_URL}/order/dispute`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({
            nOperation: parseInt(operationNumber, 10),
          }),
        });
  
        if (response.ok) {
          alert('Dispute has been started successfully');
        } else {
          alert('Failed to start dispute');
        }
      } catch (error) {
        console.error('Error sending dispute request:', error);
        alert('Error starting dispute');
      }
    }
  };

  const handleTransactionCompleted = async () => {
    const token = localStorage.getItem('authToken');
    const body = {
      nOperation: parseInt(operationNumber, 10),
      address
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/order/verified`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error('Failed to complete transaction');
      }

      console.log('Transaction completed');
    } catch (error) {
      console.error('Error completing transaction:', error);
    }
  };

  const handleReleaseUSD = async () => {
    const token = localStorage.getItem('authToken');
    const body = {
      nOperation: parseInt(operationNumber, 10),
      address
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/order/finish`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error('Failed to release USD');
      }

      console.log('USD released');
    } catch (error) {
      console.error('Error releasing USD:', error);
    }
  };

  const handleCancel = async () => {
    const token = localStorage.getItem('authToken');
    const body = {
      nOperation: parseInt(operationNumber, 10),
      address
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/order/cancel`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error('Failed to cancel order');
      }

      console.log('Order canceled');
    } catch (error) {
      console.error('Error cancelling order:', error);
    }
  };

  const handleRefresh = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${config.API_BASE_URL}/Refresh`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to refresh data');
      }

      const data = await response.json();
      globalDispatch({
        type: 'UPDATE_GLOBAL_STATE',
        payload: {
          user: data.user,
          token: data.token,
          orderWithdrawl: data.orderWithdrawl,
          historyTransaction: data.historyTransaction,
        },
      });
      console.log('Refreshed data');
      navigate('/market');
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  const formatCoins = (num) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : 'N/A';
  };

  const formatUSD = (num) => {
    return num !== undefined ? (num % 1 === 0 ? `${num},00` : num.toFixed(2).replace('.', ',')) : 'N/A';
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  const { order } = orderInfo || {};
  const { operationNumber: orderNumber, stock, price, owner, trader, type } = order || {};

  const isUserOwner = username === owner;
  const isUserTrader = username === trader;

  const transactionButtonDisabled = !(status === 'prossesing');
  const releaseUSDButtonDisabled = !(status === 'verified');
  const cancelButtonDisabled = !(status === 'prossesing');
  const allButtonsDisabled = status === 'open' || status === 'closed';

  const buttonElements = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, padding: 2, flexWrap: 'wrap' }}>
      {isUserOwner && type === 'sell' && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: allButtonsDisabled ? '#cccccc' : (transactionButtonDisabled || buttonDisabled ? '#cccccc' : '#28a745'),
            color: allButtonsDisabled ? '#666666' : 'white',
            fontWeight: 'bold',
            borderRadius: '8px',
            marginBottom: '8px',
            width: '48%',
          }}
          disabled={transactionButtonDisabled || allButtonsDisabled || buttonDisabled}
          onClick={handleClickButton}
        >
          Transaction Completed
        </Button>
      )}
      {isUserTrader && type === 'buy' && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: allButtonsDisabled ? '#cccccc' : (transactionButtonDisabled || buttonDisabled ? '#cccccc' : '#28a745'),
            color: allButtonsDisabled ? '#666666' : 'white',
            fontWeight: 'bold',
            borderRadius: '8px',
            marginBottom: '8px',
            width: '48%',
          }}
          disabled={transactionButtonDisabled || allButtonsDisabled || buttonDisabled}
          onClick={handleClickButton}
        >
          Transaction Completed
        </Button>
      )}
      {isUserOwner && type === 'buy' && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: allButtonsDisabled ? '#cccccc' : (releaseUSDButtonDisabled ? '#cccccc' : '#ffc107'),
            color: allButtonsDisabled ? '#666666' : 'white',
            fontWeight: 'bold',
            borderRadius: '8px',
            marginBottom: '8px',
            width: '48%',
          }}
          disabled={releaseUSDButtonDisabled || allButtonsDisabled}
          onClick={handleReleaseUSDDialog} // Cambiado aquí
        >
          Release USD
        </Button>
      )}
      {isUserTrader && type === 'sell' && (
        <Button
          variant="contained"
          sx={{
            backgroundColor: allButtonsDisabled ? '#cccccc' : (releaseUSDButtonDisabled ? '#cccccc' : '#ffc107'),
            color: allButtonsDisabled ? '#666666' : 'white',
            fontWeight: 'bold',
            borderRadius: '8px',
            marginBottom: '8px',
            width: '48%',
          }}
          disabled={releaseUSDButtonDisabled || allButtonsDisabled}
          onClick={handleReleaseUSDDialog} // Cambiado aquí
        >
          Release USD
        </Button>
      )}
      <Button
        variant="contained"
        sx={{
          backgroundColor: cancelButtonDisabled ? '#cccccc' : '#dc3545',
          color: cancelButtonDisabled ? '#666666' : 'white',
          fontWeight: 'bold',
          borderRadius: '8px',
          marginBottom: '8px',
          width: '48%',
        }}
        disabled={cancelButtonDisabled}
        onClick={handleCancel}
      >
        Cancel
      </Button>

      <Dialog
        open={openConfirmationDialog}
        onClose={() => handleCloseConfirmationDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#007bff', // Azul
            color: 'white',
            boxShadow: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to complete this transaction?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseConfirmationDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={() => handleCloseConfirmationDialog(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openReleaseUSDDialog}
        onClose={() => handleCloseReleaseUSDDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#007bff', // Azul
            color: 'white',
            boxShadow: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <DialogTitle>Release USD</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to release USD?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseReleaseUSDDialog(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={() => handleCloseReleaseUSDDialog(true)} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  return (
    <Box sx={{ padding: 2, display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, height: '80vh' }}>
      <Card sx={{ 
        background: 'linear-gradient(270deg, #1e1e1e 0%, #121212 100%)', 
        color: 'white', 
        borderRadius: '8px', 
        padding: '20px', 
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '350px', // Agregado para evitar que se corte el contenido
      }}>
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Operation Information</Typography>
        </Box>
        <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5, marginBottom: 2 }} />
        <CardContent sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Operation Number</Typography>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{orderNumber}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Owner</Typography>
              <Typography variant="h6">{owner}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Trader</Typography>
              <Typography variant="h6">{trader}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Price</Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{formatUSD(price)} USD</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Stock</Typography>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                {formatCoins(stock)}
                <img src="https://i.postimg.cc/nr9DjwZK/e66e4f48-2b99-45f8-b8fc-74d43e391d1c.png" alt="Coin Icon" style={{ marginLeft: 8, width: 24, height: 24 }} />
              </Typography>
            </Box>
          </Box>
        </CardContent>
        {!isMobile && buttonElements}
      </Card>
      <Card sx={{ 
        background: 'linear-gradient(270deg, #1e1e1e 0%, #121212 100%)', 
        color: 'white', 
        borderRadius: '8px', 
        padding: '10px', 
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '400px', // Aumentado para mayor visibilidad del chat
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Chat</Typography>
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon sx={{ color: 'white' }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
<MenuItem
  onClick={() => {
    handleMenuClose(); 
    handleDispute(); 
  }}
>
  Dispute
</MenuItem>
            <MenuItem onClick={handleMenuClose}>Report</MenuItem>
          </Menu>
        </Box>
        <CardContent sx={{ flexGrow: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse', padding: 0, margin: 0 }}>
          <List sx={{ 
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: '#0057b7 #1e1e1e',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'linear-gradient(270deg, #0057b7 0%, #001f54 100%)',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: 'linear-gradient(270deg, #0057b7 0%, #003086 100%)',
            },
            '&::-webkit-scrollbar-track': {
              background: '#1e1e1e',
            },
            margin: 0,
            padding: 0
          }}>
            {chatMessages.map((msg, index) => {
              if (msg.message.includes('snapshot') || msg.message.includes('prossesing') || msg.message.includes('se conecto')) return null;

              const isOwnMessage = msg.username === username;
              return (
                <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: isOwnMessage ? 'flex-end' : 'flex-start', gap: 1 }}>
                  {msg.username === 'Server' ? (
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                      <Typography variant="subtitle2" sx={{ textDecoration: 'underline', fontSize: '0.9rem', color: 'white' }}>
                        {msg.message}
                      </Typography>
                    </Box>
                  ) : (
                    <Card sx={{ 
                      background: isOwnMessage ? 'linear-gradient(270deg, #0033cc 0%, #000000 100%)' : 'linear-gradient(270deg, #4d4d4d 0%, #1e1e1e 100%)', 
                      color: 'white', 
                      padding: 1,
                      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.7)',
                      borderRadius: '8px',
                      maxWidth: '80%',
                      display: 'flex',
                      flexDirection: 'column',
                      wordBreak: 'break-word',
                    }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{msg.username}</Typography>
                        <Typography variant="body2" sx={{ fontSize: '0.8rem', color: 'rgba(255, 255, 255, 0.7)', ml: 2 }}>
                          Message
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ fontSize: '1rem', marginTop: '4px' }}>{msg.message}</Typography>
                    </Card>
                  )}
                </ListItem>
              );
            })}
            <div ref={chatEndRef} />
          </List>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
          <TextField
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            variant="outlined"
            fullWidth
            sx={{ input: { color: 'white' } }}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSendMessage}>
                    <SendIcon sx={{ color: 'white' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Card>
      {isMobile && buttonElements}
      <Dialog
        open={openDialog}
        onClose={() => {}}
        disableEscapeKeyDown
      >
        <DialogTitle sx={{ backgroundColor: '#0057b7', color: 'white' }}>
          Operation Ended
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#0057b7' }}>
          <DialogContentText sx={{ color: 'white' }}>
            The operation has ended. Would you like to refresh your data and return to the market?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: '#0057b7' }}>
          <Button
            onClick={() => {
              setOpenDialog(false);
              handleRefresh();
            }}
            sx={{ color: 'white' }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Selling;


