import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid, Accordion, AccordionSummary, AccordionDetails, IconButton, Dialog, DialogContent, DialogTitle, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const cardData = [
  {
    title: "Introducing",
    description: "",
    mediaType: 'image',
    color: '#3060A8',
    imageUrl: 'https://i.imgur.com/mT3Z6Ck.png'
  },
  {
    title: "Ronin Safe",
    description: "",
    mediaType: 'image',
    color: '#3060A8',
    imageUrl: 'https://i.imgur.com/NkDeqIA.png'
  },
  {
    title: "Trading Platform Guide",
    description: "",
    mediaType: 'image',
    color: '#3060A8',
    imageUrl: 'https://i.imgur.com/1n2HN66.png'
  },
  {
    title: "Trading Platform Guide",
    description: "",
    mediaType: 'image',
    color: '#3060A8',
    imageUrl: 'https://i.imgur.com/PaFC6rJ.png'
  }
];

const carouselData = [
  {
    mediaType: 'image',
    mediaUrl: 'https://img.freepik.com/vector-gratis/proximamente-texto-sobre-fondo-oscuro-amanecer-abstracto-efecto-movimiento_91128-1641.jpg?t=st=1724828069~exp=1724831669~hmac=0a075a75a9be8f251266922d311dd82e4aabd44d03e4cb0af5b60bceaa81650b&w=740',
    altText: ''
  },
  {
    mediaType: 'image',
    mediaUrl: 'https://img.freepik.com/vector-gratis/proximamente-texto-sobre-fondo-oscuro-amanecer-abstracto-efecto-movimiento_91128-1641.jpg?t=st=1724828069~exp=1724831669~hmac=0a075a75a9be8f251266922d311dd82e4aabd44d03e4cb0af5b60bceaa81650b&w=740',
    altText: ''
  },
  {
    mediaType: 'image',
    mediaUrl: 'https://img.freepik.com/vector-gratis/proximamente-texto-sobre-fondo-oscuro-amanecer-abstracto-efecto-movimiento_91128-1641.jpg?t=st=1724828069~exp=1724831669~hmac=0a075a75a9be8f251266922d311dd82e4aabd44d03e4cb0af5b60bceaa81650b&w=740',
    altText: ''
  }
];

const FAQ = [
  {
    question: "What is the P2P Market?",
    answer: "The P2P market for coins is a platform that allows users to exchange cryptocurrencies directly with each other, using smart contracts and escrow systems to secure transactions. It offers an intuitive and secure interface, built on the Ronin blockchain, and requires identity verification and VIP membership to operate."
  },
  {
    question: "How can I join?",
    answer: "To join, you need to create an account with your Ronin Wallet linked to your Pixels Online account.\n\n1. Navigate to pixels.roningamingtools.xyz.\n2. Click on \"Connect Wallet\" in the lower left corner of the site.\n3. Sign in with your Ronin Wallet (Safe)."
  },
  {
    question: "Will support be available?",
    answer: "Yes, we offer support through our Discord channel. Feel free to contact us if you need help."
  }
];

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [openImage, setOpenImage] = useState(null); // Estado para manejar el modal de imagen
  const itemsPerSlide = 3;

  // Para la pantalla completa del modal
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.ceil(carouselData.length / itemsPerSlide));
  };

  const handlePrevious = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + Math.ceil(carouselData.length / itemsPerSlide)) % Math.ceil(carouselData.length / itemsPerSlide));
  };

  const getCurrentItems = () => {
    const start = currentSlide * itemsPerSlide;
    const end = start + itemsPerSlide;
    return carouselData.slice(start, end);
  };

  return (
    <Box sx={{ textAlign: 'center', mt: 0 }}>
      <Box sx={{ position: 'relative', mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          "Exciting news coming soon!"
        </Typography>
        <Box sx={{ position: 'relative', overflow: 'hidden', maxWidth: 800, mx: 'auto' }}>
          <Grid container spacing={2} justifyContent="center">
            {getCurrentItems().map((item, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card sx={{ bgcolor: '#1e1e1e', color: '#ffffff' }}>
                  <CardMedia
                    component="img"
                    height="auto"
                    image={item.mediaUrl}
                    alt={item.altText}
                    sx={{ maxWidth: '100%' }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
          <IconButton
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              bgcolor: '#ffffff',
              color: '#000000',
              '&:hover': {
                bgcolor: '#f0f0f0'
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              bgcolor: '#ffffff',
              color: '#000000',
              '&:hover': {
                bgcolor: '#f0f0f0'
              }
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Sección de tarjetas */}
      <Grid container spacing={2} justifyContent="center">
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ bgcolor: '#1e1e1e', color: '#ffffff', boxShadow: 3, borderRadius: 2 }} 
              onClick={() => setOpenImage(card.imageUrl)}
            >
              <CardMedia
                component="img"
                height="auto"
                image={card.imageUrl}  // Aquí se muestra la imagen de la tarjeta
                alt={card.title}
                sx={{ cursor: 'pointer', objectFit: 'cover', maxWidth: '100%' }}
                
              />
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {card.title}
                </Typography>
                <Typography variant="body2">
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal para agrandar la imagen */}
      <Dialog
        open={!!openImage}
        onClose={() => setOpenImage(null)}
        fullScreen={fullScreen} // Pantalla completa en dispositivos móviles
        PaperProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'transparent',
            boxShadow: 'none',
            border: 'none'
          }
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <img 
            src={openImage} 
            alt="Enlarged view" 
            style={{ 
              width: '100%', 
              height: 'auto', 
              maxHeight: '100vh', 
              objectFit: 'contain' 
            }} 
          />
        </DialogContent>
      </Dialog>

      {/* Sección de preguntas frecuentes */}
      <Box sx={{ mt: 4, maxWidth: 600, mx: 'auto' }}>
        <Typography variant="h6" sx={{ mb: 2, color: '#ffffff' }}>
          Frequently Asked Questions
        </Typography>
        {FAQ.map((item, index) => (
          <Accordion key={index} sx={{ bgcolor: '#2c2c2c', mb: 1, color: '#ffffff' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />}
              sx={{ bgcolor: '#333', borderBottom: '1px solid #444' }}
            >
              <Typography>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Home;
