import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Card } from '@mui/material';
import { WalletSDK } from '@roninnetwork/wallet-sdk';
import Notification from './Notification';
import { styled, keyframes } from '@mui/material/styles';
import Web3 from '../libs/web3/dist/web3.min.js';
import icon from '../assets/RoninGamingTools.png';

const Register = () => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isWaitingTransaction, setIsWaitingTransaction] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [web3, setWeb3] = useState(null);
  const [verificationResult, setVerificationResult] = useState(null);
  const [isDiscordVerified, setIsDiscordVerified] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
  const CLIENT_SECRET = process.env.REACT_APP_DISCORD_CLIENT_SECRET;
  const REDIRECT_URI = `${window.location.origin}/auth/discord/callback`;
  const GUILD_ID = process.env.REACT_APP_DISCORD_GUILD_ID;

  const handleMessage = (event) => {
    if (event.origin !== window.location.origin) return;
    if (event.data.type === 'DISCORD_AUTH_SUCCESS') {
      setNotification({ open: true, message: 'Autenticado en Discord', severity: 'success' });
      fetchAccessToken(event.data.code)
        .then(accessToken => {
          verifyDiscordMembership(accessToken)
            .then(isVerified => {
              if (isVerified) {
                connectRoninWallet().then(registerFree);
              }
            })
            .catch(error => {
              setNotification({ open: true, message: error.message, severity: 'error' });
            });
        })
        .catch(error => {
          setNotification({ open: true, message: error.message, severity: 'error' });
        });
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const redirectToDiscord = () => {
    return new Promise((resolve, reject) => {
      const scope = encodeURIComponent('identify guilds');
      const responseType = 'code';
      const discordAuthUrl = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&response_type=${responseType}&scope=${scope}`;
      
      const popup = window.open(discordAuthUrl, 'discordAuth', 'width=500,height=600');

      const interval = setInterval(() => {
        try {
          if (popup.closed) {
            clearInterval(interval);
           // reject('Discord authentication window closed');
          }
        } catch (err) {
          clearInterval(interval);
         // reject(err);
        }
      }, 1000);
    });
  };

  const fetchAccessToken = async (code) => {
    const response = await fetch('https://discord.com/api/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: 'authorization_code',
        code,
        redirect_uri: REDIRECT_URI,
      }),
    });

    const data = await response.json();
    if (data.access_token) {
      setAccessToken(data.access_token);
      return data.access_token;
    } else {
      throw new Error('Error obtaining access token');
    }
  };

  const verifyDiscordMembership = async (accessToken) => {
    const response = await fetch('https://discord.com/api/users/@me/guilds', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const guilds = await response.json();
    const isInGuild = guilds.some(guild => guild.id === GUILD_ID);

    if (isInGuild) {
      setIsDiscordVerified(true);
      return true;
    } else {
      setIsDiscordVerified(false);
      window.open('https://discord.gg/22myPvuBng', '_blank'); // Abrir nueva pestaña con el enlace de invitación
      throw new Error('You must be a member of the Ronin Gaming Tools Discord server to register.');
    }
  };

  const connectRoninWallet = async () => {
    setIsConnecting(true);
    const isInstalled = 'ronin' in window;

    if (!isInstalled) {
      window.open('https://wallet.roninchain.com', '_blank');
      setIsConnecting(false);
      return;
    }

    const sdk = new WalletSDK({
      dappMetadata: {
        url: window.location.origin,
        name: 'Ronin Gaming Tools',
        description: 'This is a test dApp',
        icon: icon,
      },
    });

    try {
      await sdk.connectInjected();
      const accounts = await sdk.requestAccounts();
      if (accounts && accounts.length > 0) {
        setWalletAddress(accounts[0]);
        const web3Instance = new Web3(new Web3.providers.HttpProvider('https://api.roninchain.com/rpc'));
        setWeb3(web3Instance);
        setIsConnecting(false);
        return accounts[0];
      } else {
        setIsConnecting(false);
      }
    } catch (error) {
      setIsConnecting(false);
    }
  };

  const handlePreRegisterClick = async () => {
    try {
      await redirectToDiscord();
    } catch (error) {
      setNotification({ open: true, message: error, severity: 'error' });
    }
  };

  const registerFree = async () => {
    let address = walletAddress;
    if (!address) {
      address = await connectRoninWallet();
    }
    if (!address) return;

    setIsRegistering(true);

    try {
      const response = await fetch('https://Pixels-Tools-Server.somee.com/RegistroFree', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          wallet: address,
          txid: "",
        }),
      });

      if (response.ok) {
        setNotification({ open: true, message: 'Pre-registered successfully', severity: 'success' });
      } else {
        const result = await response.json();
        setNotification({ open: true, message: result.message || 'Error during registration', severity: 'error' });
      }
    } catch (error) {
      setNotification({ open: true, message: 'Error during registration', severity: 'error' });
    } finally {
      setIsRegistering(false);
    }
  };

  const registerVIP = async () => {
    let address = walletAddress;
    if (!address) {
      address = await connectRoninWallet();
    }
    if (!address || !web3) return;

    setIsRegistering(true);
    setIsWaitingTransaction(true);

    const pixelsTokenAddress = '0x7eae20d11ef8c779433eb24503def900b9d28ad7';
    const recipientAddress = '0x0cdd20fb0f8f40deddebae7e66a780f49f748d8f';
    const amount = '5'; // 5 PIXELS

    try {
      const sdk = new WalletSDK();
      await sdk.connectInjected();

      const transactionParameters = {
        to: pixelsTokenAddress,
        from: address,
        data: new web3.eth.Contract([
          {
            "constant": false,
            "inputs": [
              { "name": "_to", "type": "address" },
              { "name": "_value", "type": "uint256" }
            ],
            "name": "transfer",
            "outputs": [{ "name": "", "type": "bool" }],
            "type": "function"
          }
        ], pixelsTokenAddress).methods.transfer(recipientAddress, web3.utils.toWei(amount, 'ether')).encodeABI(),
      };

      const txHash = await window.ronin.provider.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });

      console.log('Transaction sent. TxHash:', txHash);

      let receipt = null;
      let retries = 30;
      while (retries > 0 && receipt === null) {
        try {
          receipt = await web3.eth.getTransactionReceipt(txHash);
          if (receipt) {
            console.log('Transaction receipt:', receipt);
          }
        } catch (error) {
          if (retries === 1) { // Solo mostrar el error en el último intento
            console.error('Error fetching transaction receipt:', error);
          }
        }
        if (receipt === null) {
          retries--;
          await new Promise(resolve => setTimeout(resolve, 10000)); // Aumentar el tiempo de espera a 10 segundos
        }
      }

      if (receipt && receipt.status) {
        console.log('Transaction confirmed:', receipt);
        const response = await fetch('https://Pixels-Tools-Server.somee.com/RegistroVip', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            wallet: address,
            txid: txHash,
          }),
        });

        if (response.ok) {
          setNotification({ open: true, message: 'VIP registered successfully', severity: 'success' });
        } else {
          const resultText = await response.text(); // Obtener el texto de la respuesta
          console.error('Error during VIP registration:', resultText);
          setNotification({ open: true, message: resultText || 'Error during VIP registration', severity: 'error' });
        }
      } else {
        console.error('Transaction failed or not found');
        setNotification({ open: true, message: 'Transaction failed or not found', severity: 'error' });
      }

    } catch (error) {
      console.error('Error during VIP registration:', error);
      setNotification({ open: true, message: 'Error during VIP registration', severity: 'error' });
    } finally {
      setIsRegistering(false);
      setIsWaitingTransaction(false);
    }
  };

  const verifyRegistration = async () => {
    let address = walletAddress;
    if (!address) {
      address = await connectRoninWallet();
    }
    if (!address) return;

    try {
      const response = await fetch(`https://Pixels-Tools-Server.somee.com/Wallet/${address}`);
      const result = await response.json();
      if (response.ok) {
        setVerificationResult(result);
      } else {
        setVerificationResult('notRegistered');
      }
    } catch (error) {
      console.error('Error during verification:', error);
      setVerificationResult('notRegistered');
      setNotification({ open: true, message: 'Error during verification', severity: 'error' });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ open: false, message: '', severity: 'success' });
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3, bgcolor: '#1e1e1e', boxShadow: 3, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Button
          variant="contained"
          onClick={() => window.open('https://discord.gg/22myPvuBng', '_blank')}
          sx={{
            bgcolor: '#5865F2',
            color: 'white',
            '&:hover': {
              bgcolor: '#4e5bb5'
            },
            width: '300px',
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="https://i.postimg.cc/tR6rsMrN/pngwing-com.png" alt="Discord Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
          Join our Discord
        </Button>
      </Box>
      {isConnecting && (
        <LoadingCard>
          <GlowingText variant="body2">
            Connecting to Ronin Wallet...
          </GlowingText>
        </LoadingCard>
      )}
      {isWaitingTransaction && (
        <LoadingCard>
          <GlowingText variant="body2">
            Waiting for transaction...
          </GlowingText>
        </LoadingCard>
      )}
      <Typography variant="h6" sx={{ textAlign: 'center', mb: 2, color: '#fff' }}>
        Registration
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <StepText>
          Ensure that your Ronin wallet is connected and linked to your Pixels account. Verify that it does not have an active membership to any alliance and join our Discord to register for free.
        </StepText>
        <Divider />
        <StepText>
          Players will be pre-registered and the first step will be verified. We only have 300 free spots available, so make sure to complete the process as soon as possible.
        </StepText>
        <Divider />
        <StepText>
          By registering as a VIP, you will be exempt from the verification requirements for free registration. The registration fee is 5 PIXELS.
        </StepText>
        <Divider />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          onClick={handlePreRegisterClick}
          disabled={isRegistering}
          sx={{
            mb: { xs: 2, sm: 0 },
            bgcolor: '#1e88e5',
            color: 'white',
            '&:hover': {
              bgcolor: '#1565c0'
            },
            width: '300px',
            textTransform: 'none'
          }}
        >
          {isRegistering ? <CircularProgress size={24} /> : 'Pre-Register for Free'}
        </Button>
        <Button
          variant="contained"
          onClick={registerVIP}
          disabled={isRegistering}
          sx={{
            bgcolor: '#1e88e5',
            color: 'white',
            '&:hover': {
              bgcolor: '#1565c0'
            },
            width: '300px',
            textTransform: 'none'
          }}
        >
          {isRegistering ? <CircularProgress size={24} /> : 'Register VIP'}
        </Button>
      </Box>
      {!verificationResult ? (
        <Button
          variant="contained"
          onClick={verifyRegistration}
          sx={{
            mt: 2,
            bgcolor: '#1e88e5',
            color: 'white',
            width: '100%',
            textTransform: 'none'
          }}
        >
          Verify Registration
        </Button>
      ) : verificationResult === 'notRegistered' ? (
        <Card sx={{ mt: 2, p: 2, bgcolor: 'red', color: 'white' }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Not Registered
          </Typography>
        </Card>
      ) : (
        <Card sx={{ mt: 2, p: 2, bgcolor: 'green', color: 'white' }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Username: {verificationResult.username}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center' }}>
            Wallet: {verificationResult.wallet}
          </Typography>
        </Card>
      )}
      <Notification
        open={notification.open}
        handleClose={handleNotificationClose}
        message={notification.message}
        severity={notification.severity}
      />
    </Box>
  );
};

const LoadingCard = styled(Card)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100px',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  color: 'white',
  marginBottom: '20px',
});

const glow = keyframes`
  0% { text-shadow: 0 0 5px #1e88e5, 0 0 10px #1e88e5, 0 0 15px #1e88e5, 0 0 20px #1e88e5; }
  100% { text-shadow: 0 0 10px #1e88e5, 0 0 20px #1e88e5, 0 0 30px #1e88e5, 0 0 40px #1e88e5; }
`;

const GlowingText = styled(Typography)({
  animation: `${glow} 1s ease-in-out infinite alternate`,
});

const StepText = styled(Typography)({
  color: '#90caf9',
  textAlign: 'center',
  maxWidth: '100%',
});

const Divider = styled('hr')({
  width: '100%',
  border: 'none',
  borderTop: '1px solid #1e88e5',
  margin: '16px 0',
});

export default Register;


