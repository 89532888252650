import React, { createContext, useReducer, useContext } from 'react';

const initialState = {
  user: null,
  token: null,
  orderWithdrawl: [],
  historyTransaction: [],
  notification: null,
  orders: { countRoom: 0, buy: [], sell: [] },
  referrer: { referrer: 'none', username: '' },
};

const GlobalStateContext = createContext(initialState);
const GlobalStateDispatchContext = createContext(() => {});

const globalStateReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_GLOBAL_STATE':
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        notification: action.payload.user.notification,
        orderWithdrawl: action.payload.orderWithdrawl,
        historyTransaction: action.payload.historyTransaction,
      };
  case 'UPDATE_REFERRER':
      return {
        ...state,
        referrer: action.payload,
      };
    case 'UPDATE_MEMBERSHIP':
      return {
        ...state,
        user: {
          ...state.user,
          membership: action.payload.membership,
          matx: action.payload.matx,
        },
      };
    case 'SET_MY_ORDER':
      return {
        ...state,
        user: {
          ...state.user,
          myOrder: action.payload,
        },
      };
    case 'RESET_MY_ORDER':
      return {
        ...state,
        user: {
          ...state.user,
          myOrder: null,
        },
      };
    case 'UPDATE_NOTIFICATION':
      console.log('Reducer: Actualizando notificación', action.payload); // Agrega este log
      return {
        ...state,
        notification: action.payload,
      };
    case 'RESET_NOTIFICATION':
      return {
        ...state,
        notification: null,
      };
    case 'UPDATE_ORDERS':
      return {
        ...state,
        orders: action.payload,
      };
    case 'RESET_GLOBAL_STATE':
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalStateDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalStateDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => useContext(GlobalStateContext);
export const useGlobalStateDispatch = () => useContext(GlobalStateDispatchContext);









