import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Box, TextField, MenuItem, Select, InputLabel, FormControl, Grid, Divider } from '@mui/material';
import { useGlobalState, useGlobalStateDispatch } from '../GlobalStateContext';
import config from './config';
import Notification from './Notification';

const MyOrder = () => {
  const { token, user } = useGlobalState();
  const dispatch = useGlobalStateDispatch();
  const [action, setAction] = useState('sell');
  const [stockOptions, setStockOptions] = useState([]);
  const [stock, setStock] = useState('');
  const [price, setPrice] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [myOrder, setMyOrder] = useState(user?.myOrder || null);

  useEffect(() => {
    if (!user) return;

    const fetchStockOptions = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/ownerPack/list`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          const membershipType = user.membership.range.toLowerCase();
          const stockNumbers = data[membershipType] || [];
          setStockOptions(stockNumbers);
        } else {
          setNotification({ open: true, message: 'Failed to fetch stock options', severity: 'error' });
        }
      } catch (error) {
        console.error('Error fetching stock options:', error);
        setNotification({ open: true, message: 'Error fetching stock options', severity: 'error' });
      }
    };

    fetchStockOptions();
  }, [token, user]);

  const handleCreateOrder = async () => {
    if (!stock || !price) {
      setNotification({ open: true, message: 'Please fill in all fields', severity: 'error' });
      return;
    }

    const body = {
      action,
      stock: parseInt(stock, 10),
      price: parseFloat(price),
      address: user?.addresses[0] || '',
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/myorder/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Order created successfully:', result); // Verificar el resultado
        setNotification({ open: true, message: 'Order created successfully', severity: 'success' });
        dispatch({ type: 'SET_MY_ORDER', payload: result.user }); // Actualizar el estado global
        setMyOrder(result.user); // Actualizar el estado local
      } else {
        const result = await response.json();
        setNotification({ open: true, message: result.message || 'Error creating order', severity: 'error' });
      }
    } catch (error) {
      console.error('Error creating order:', error);
      setNotification({ open: true, message: 'Error creating order', severity: 'error' });
    }
  };

  const handleCancelOrder = async () => {
    const body = {
      address: user?.addresses[0] || '',
      nOperation: myOrder.noperation,
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/myorder/close`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setNotification({ open: true, message: 'Order cancelled successfully', severity: 'success' });
        dispatch({ type: 'RESET_MY_ORDER' }); // Resetear el estado global
        setMyOrder(null); // Resetear el estado local
      } else {
        const result = await response.json();
        setNotification({ open: true, message: result.message || 'Error cancelling order', severity: 'error' });
      }
    } catch (error) {
      console.error('Error cancelling order:', error);
      setNotification({ open: true, message: 'Error cancelling order', severity: 'error' });
    }
  };

  const handleModifyOrder = async () => {
    const body = {
      nOperation: myOrder.noperation,
      address: user?.addresses[0] || '',
      price: parseFloat(newPrice),
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/myorder/edit`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Order modified successfully:', result); // Verificar el resultado
        setNotification({ open: true, message: 'Order modified successfully', severity: 'success' });
        const updatedOrder = { ...myOrder, price: parseFloat(newPrice) };
        dispatch({ type: 'SET_MY_ORDER', payload: updatedOrder }); // Actualizar el estado global
        setMyOrder(updatedOrder); // Actualizar el estado local
        setIsEditing(false); // Salir del modo de edición
      } else {
        const result = await response.json();
        setNotification({ open: true, message: result.message || 'Error modifying order', severity: 'error' });
      }
    } catch (error) {
      console.error('Error modifying order:', error);
      setNotification({ open: true, message: 'Error modifying order', severity: 'error' });
    }
  };

  if (!user) {
    return <Typography variant="h6" color="error">User not found</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      {myOrder ? (
        <Card sx={{ width: '100%', maxWidth: 600, background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', color: 'white' }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              My Order
            </Typography>
            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.12)' }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Operation Number
                </Typography>
                <Typography variant="h6">{myOrder.noperation}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Type
                </Typography>
                <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>{myOrder.type}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Status
                </Typography>
                <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>{myOrder.status}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Stock
                </Typography>
                <Typography variant="h6">{myOrder.stock.toLocaleString()}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Price
                </Typography>
                {isEditing ? (
                  <TextField
                    value={newPrice}
                    onChange={(e) => setNewPrice(e.target.value)}
                    fullWidth
                    variant="outlined"
                    sx={{ input: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#0d47a1' } } }}
                  />
                ) : (
                  <Typography variant="h6">{myOrder.price.toFixed(2)} USD</Typography>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.12)' }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              {isEditing ? (
                <>
                  <Button variant="contained" color="primary" onClick={handleModifyOrder}>
                    Confirm
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="contained" color="primary" onClick={() => setIsEditing(true)}>
                    Modify
                  </Button>
                  <Button variant="contained" color="error" onClick={handleCancelOrder}>
                    Cancel Order
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card sx={{ width: '100%', maxWidth: 600, background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', color: 'white' }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Create New Order
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="action-label" sx={{ color: 'white' }}>Action</InputLabel>
              <Select
                labelId="action-label"
                value={action}
                label="Action"
                onChange={(e) => setAction(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0d47a1',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  backgroundColor: 'primary',
                  color: 'white', // Color del texto seleccionado
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: '#0d47a1',
                      color: 'white',
                    },
                  },
                }}
              >
                <MenuItem value="sell">Sell</MenuItem>
                <MenuItem value="buy">Buy</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="stock-label" sx={{ color: 'white' }}>Stock</InputLabel>
              <Select
                labelId="stock-label"
                value={stock}
                label="Stock"
                onChange={(e) => setStock(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#0d47a1',
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'white',
                  },
                  backgroundColor: 'primary',
                  color: 'white', // Color del texto seleccionado
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: '#0d47a1',
                      color: 'white',
                    },
                  },
                }}
              >
                {stockOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Price (USD)"
              type="number"
              fullWidth
              sx={{ mb: 2, input: { color: 'white' }, '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#0d47a1' }, backgroundColor: 'primary' } }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleCreateOrder}>
              Create Order
            </Button>
          </CardContent>
        </Card>
      )}
      <Notification
        open={notification.open}
        handleClose={() => setNotification({ open: false })}
        message={notification.message}
        severity={notification.severity}
      />
    </Box>
  );
};

export default MyOrder;














