import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, Typography, Card, CardContent, InputAdornment, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Track = () => {
  const [baseNumber, setBaseNumber] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [landInfo1, setLandInfo1] = useState(null);
  const [landInfo2, setLandInfo2] = useState(null);

  useEffect(() => {
    fetchLandInfo1();
    fetchLandInfo2();
  }, [baseNumber]);

  const fetchLandInfo1 = async () => {
    try {
      const response = await fetch(`https://www.rojanelos-tools.somee.com/getLand/${baseNumber}`);
      const data = await response.json();
      console.log('Land Info 1:', data); 
      setLandInfo1(data);
    } catch (error) {
      console.error('Error fetching land info 1:', error);
    }
  };

  const fetchLandInfo2 = async () => {
    try {
      const response = await fetch(`https://www.rojanelos-tools.somee.com/getLand/${baseNumber + 1}`);
      const data = await response.json();
      console.log('Land Info 2:', data); 
      setLandInfo2(data);
    } catch (error) {
      console.error('Error fetching land info 2:', error);
    }
  };

  const handleIncrement = () => {
    if (baseNumber < 4999) {
      setBaseNumber(prev => prev + 2);
    }
  };

  const handleDecrement = () => {
    if (baseNumber > 1) {
      setBaseNumber(prev => prev - 2);
    }
  };

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value) && value >= 1 && value <= 5000) {
      setInputValue(value);
    }
  };

  const handleSearch = () => {
    if (inputValue >= 1 && inputValue <= 5000) {
      setBaseNumber(inputValue);
    }
  };

  const handleCopy = (number) => {
    navigator.clipboard.writeText(number.toString())
      .catch(err => console.error('Failed to copy:', err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      handleIncrement();
      fetchLandInfo1();
      fetchLandInfo2();
    } else if (event.key === 'ArrowLeft') {
      handleDecrement();
      fetchLandInfo1();
      fetchLandInfo2();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [baseNumber]);

  const renderCard = (iframeSrc, owner, landPrivate, housePrivate, title, number) => (
    <Card sx={{ margin: 1, padding: 0, width: '100%' }}>
      <CardContent>
        <iframe
          src={iframeSrc}
          width="100%"
          height="400px"
          title={title}
          style={{ border: '2px solid #ccc', borderRadius: '8px' }}
        />
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
          <Typography variant="h6" sx={{ mb: { xs: 1, sm: 0 } }}>
            {title}
          </Typography>
          <IconButton 
  onClick={() => {
    handleCopy(number);
    fetchLandInfo1();
    fetchLandInfo2();
  }} 
  sx={{ mt: { xs: 1, sm: 0 } }}
>
  <ContentCopyIcon />
</IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Land Owner:</strong> {owner || 'Unknown'}
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <strong>Land Permissions:</strong>
            <span style={{ color: landPrivate ? 'red' : 'green' }}>
              {landPrivate ? 'Private' : 'Public'}
            </span>
          </Typography>
          <Typography variant="body1">
            <strong>House Permissions:</strong>
            <span style={{ color: housePrivate ? 'red' : 'green' }}>
              {housePrivate ? 'Private' : 'Public'}
            </span>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ textAlign: 'center', mt: -2 }}>
      <Card sx={{ marginBottom: 1, padding: 0 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: -1 }}>
            <IconButton onClick={handleDecrement} color="primary">
              <ArrowBackIosIcon />
            </IconButton>
            <TextField
              value={inputValue}
              onChange={handleInputChange}
              variant="outlined"
              label="Buscar número"
              type="number"
              inputProps={{ min: 1, max: 5000 }}
              sx={{ mx: 2, width: { xs: '100%', sm: '300px' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton onClick={handleIncrement} color="primary">
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
      <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', sm: 'row' }, maxWidth: '100%', width: '100%' }}>
        <Grid item xs={12} sm={6} md={6}>
          {renderCard(
            `https://play.pixels.xyz/pixels/share/${baseNumber}`,
            landInfo1?.username,
            landInfo1?.landPrivate,
            landInfo1?.housePrivate,
            `Land ${baseNumber} / House ${baseNumber}`,
            baseNumber
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {renderCard(
            `https://play.pixels.xyz/pixels/share/${baseNumber + 1}`,
            landInfo2?.username,
            landInfo2?.landPrivate,
            landInfo2?.housePrivate,
            `Land ${baseNumber + 1} / House ${baseNumber + 1}`,
            baseNumber + 1
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Track;
