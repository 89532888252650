import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

const Terms = () => {
  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3, bgcolor: '#1e1e1e', boxShadow: 3, borderRadius: 2, color: 'white' }}>
      <Card sx={{ backgroundColor: 'rgb(21, 32, 43)', background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', color: 'white' }}>
        <CardContent>
          <Typography variant="h5" sx={{ textAlign: 'center', mb: 2, textDecoration: 'underline' }}>
            Terms and Conditions
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            In the moment the competition begins or at the time of registration is when the points for the top ranking will start to be counted.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Terms;

