import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent, Grid, TextField, useMediaQuery, Button, IconButton, Tooltip, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { WalletSDK } from '@roninnetwork/wallet-sdk';
import Web3 from '../libs/web3/dist/web3.min.js';
import Notification from './Notification';
import config from './config';
import { useGlobalState, useGlobalStateDispatch } from '../GlobalStateContext';

const Profile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const { user, orderWithdrawl, historyTransaction, token, referrer } = useGlobalState();
  const dispatch = useGlobalStateDispatch();
  const [isRegistering, setIsRegistering] = useState(false);
  const [isWaitingTransaction, setIsWaitingTransaction] = useState(false);
  const [walletAddress, setWalletAddress] = useState(localStorage.getItem('walletAddress') || '');
  const [referrerInput, setReferrerInput] = useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [web3, setWeb3] = useState(null);
  const [openDepositDialog, setOpenDepositDialog] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (walletAddress && !web3) {
      const web3Instance = new Web3(new Web3.providers.HttpProvider('https://api.roninchain.com/rpc'));
      setWeb3(web3Instance);
    }
  }, [walletAddress, web3]);

  const connectRoninWallet = async () => {
    const isInstalled = 'ronin' in window;

    if (!isInstalled) {
      window.open('https://wallet.roninchain.com', '_blank');
      return;
    }

    const sdk = new WalletSDK({
      dappMetadata: {
        url: window.location.origin,
        name: 'Ronin Gaming Tools',
        description: 'This is a test dApp',
      },
    });

    try {
      await sdk.connectInjected();
      const accounts = await sdk.requestAccounts();
      if (accounts && accounts.length > 0) {
        setWalletAddress(accounts[0]);
        const web3Instance = new Web3(new Web3.providers.HttpProvider('https://api.roninchain.com/rpc'));
        setWeb3(web3Instance);
        return accounts[0];
      }
    } catch (error) {
      console.error('Error connecting to Ronin Wallet:', error);
    }
  };

  const handleDeposit = async () => {
    let address = walletAddress;
    if (!address) {
      address = await connectRoninWallet();
    }
    if (!address || !web3) return;

    setIsRegistering(true);
    setIsWaitingTransaction(true);

    const slpTokenAddress = '0x0b7007c13325c48911f73a2dad5fa5dcbf808adc';
    const recipientAddress = '0xB3880Ca04b4e702757b8d71ab1FeC2D7519fe463';
    const amount = web3.utils.toWei(depositAmount.toString(), 'mwei');

    try {
      const sdk = new WalletSDK();
      await sdk.connectInjected();

      const transactionParameters = {
        to: slpTokenAddress,
        from: address,
        data: new web3.eth.Contract([
          {
            constant: false,
            inputs: [
              { name: "_to", type: "address" },
              { name: "_value", type: "uint256" }
            ],
            name: "transfer",
            outputs: [{ name: "", type: "bool" }],
            type: "function"
          }
        ], slpTokenAddress).methods.transfer(recipientAddress, amount).encodeABI(),
      };

      const txHash = await window.ronin.provider.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });

      console.log('Transaction sent. TxHash:', txHash);

      let receipt = null;
      let retries = 30;
      while (retries > 0 && receipt === null) {
        try {
          receipt = await web3.eth.getTransactionReceipt(txHash);
          if (receipt) {
            console.log('Transaction receipt:', receipt);
          }
        } catch (error) {
          if (retries === 1) {
            console.error('Error fetching transaction receipt:', error);
          }
        }
        if (receipt === null) {
          retries--;
          await new Promise(resolve => setTimeout(resolve, 10000));
        }
      }

      if (receipt && receipt.status) {
        console.log('Transaction confirmed:', receipt);
        const response = await fetch(`${config.API_BASE_URL}/MATXDeposit`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ txId: txHash }),
        });

        if (response.ok) {
          const result = await response.json();
          dispatch({
            type: 'UPDATE_GLOBAL_STATE',
            payload: {
              user: {
                ...user,
                matx: result.user.matx,
              },
              token,
              orderWithdrawl,
              historyTransaction: [result.historyTransaction[0], ...historyTransaction],
            },
          });
          setNotification({ open: true, message: 'Deposit successful', severity: 'success' });
        } else {
          const result = await response.json();
          setNotification({ open: true, message: result.message || 'Error during deposit', severity: 'error' });
        }
      } else {
        console.error('Transaction failed or not found');
        setNotification({ open: true, message: 'Transaction failed or not found', severity: 'error' });
      }

    } catch (error) {
      console.error('Error during deposit:', error);
      setNotification({ open: true, message: 'Error during deposit', severity: 'error' });
    } finally {
      setIsRegistering(false);
      setIsWaitingTransaction(false);
      setOpenDepositDialog(false);
    }
  };

  const handleWithdraw = async () => {
    if (withdrawAmount < 5) {
      setNotification({ open: true, message: 'Minimum withdrawal amount is 5 MATX', severity: 'error' });
      return;
    }

    setIsRegistering(true);
    setIsWaitingTransaction(true);

    try {
      const response = await fetch(`${config.API_BASE_URL}/MATXWithdraw`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ address: walletAddress, matx: parseFloat(withdrawAmount) }),
      });

      if (response.ok) {
        const result = await response.json();
        dispatch({
          type: 'UPDATE_GLOBAL_STATE',
          payload: {
            user: {
              ...user,
              matx: result.user.matx,
            },
            token,
            orderWithdrawl: result.user.listActivitys,
            historyTransaction: [result.historyTransaction[0], ...historyTransaction],
          },
        });
        setNotification({ open: true, message: 'Withdrawal successful', severity: 'success' });
      } else {
        const result = await response.json();
        setNotification({ open: true, message: result.message || 'Error during withdrawal', severity: 'error' });
      }
    } catch (error) {
      console.error('Error during withdrawal:', error);
      setNotification({ open: true, message: 'Error during withdrawal', severity: 'error' });
    } finally {
      setIsRegistering(false);
      setIsWaitingTransaction(false);
      setOpenWithdrawDialog(false);
    }
  };

  const handleOpenDepositDialog = () => {
    setOpenDepositDialog(true);
  };

  const handleCloseDepositDialog = () => {
    setOpenDepositDialog(false);
  };

  const handleOpenWithdrawDialog = () => {
    setOpenWithdrawDialog(true);
  };

  const handleCloseWithdrawDialog = () => {
    setOpenWithdrawDialog(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Only allow numbers, dots and commas
    const regex = /^[0-9]*[.,]?[0-9]*$/;
    if (regex.test(value)) {
      setDepositAmount(value);
    }
  };

  const handleWithdrawInputChange = (e) => {
    const value = e.target.value;
    // Only allow numbers, dots and commas
    const regex = /^[0-9]*[.,]?[0-9]*$/;
    if (regex.test(value)) {
      setWithdrawAmount(value > user.matx ? user.matx : value);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleAddActivityClick = () => {
    navigate('/activities');
  };

  const handleAddReferrer = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/Referrer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ referrer }),
      });

      if (response.ok) {
        setNotification({ open: true, message: 'Referrer added successfully', severity: 'success' });
      } else {
        const result = await response.json();
        setNotification({ open: true, message: result.message || 'Error adding referrer', severity: 'error' });
      }
    } catch (error) {
      console.error('Error adding referrer:', error);
      setNotification({ open: true, message: 'Error adding referrer', severity: 'error' });
    }
  };

  const truncateMiddle = (str, length) => {
    if (!str) return '';
    if (str.length <= length) return str;
    const start = str.slice(0, length / 2);
    const end = str.slice(-length / 2);
    return `${start}...${end}`;
  };

  const renderOrderWithdrawals = () => {
    if (!orderWithdrawl || orderWithdrawl.length === 0) {
      return <Typography>No order withdrawals found.</Typography>;
    }
    return isMobile || isTablet ? (
      <Box sx={{ marginTop: 2 }}>
        {orderWithdrawl.map((order, index) => (
          <Box key={index} sx={{ marginBottom: 2, backgroundColor: '#1d1d1d', padding: 2, borderRadius: 1 }}>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>Address:</strong> {truncateMiddle(order.address, isMobile ? 10 : 20)}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>Amount:</strong> {order.amount}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>Created:</strong> {new Date(order.createdActivity).toLocaleString()}</Typography>
            <Typography variant="body1" sx={{ color: 'black', backgroundColor: 'yellow', display: 'inline-block', padding: '4px 8px', borderRadius: '4px' }}>processing</Typography>
          </Box>
        ))}
      </Box>
    ) : (
      <TableContainer component={Paper} sx={{ marginTop: 2, backgroundColor: '#1e1e1e', color: 'white' }}>
        <Typography variant="h6" sx={{ padding: 2, color: 'white' }}>Order Withdrawals</Typography>
        <Table aria-label="order withdrawals table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'rgba(61, 128, 223, 1)' }}>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Address</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Created</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderWithdrawl.map((order, index) => (
              <TableRow key={index} sx={{ backgroundColor: '#1d1d1d' }}>
                <TableCell sx={{ color: 'white' }}>{truncateMiddle(order.address, 20)}</TableCell>
                <TableCell sx={{ color: 'white' }}>{order.amount}</TableCell>
                <TableCell sx={{ color: 'white' }}>{new Date(order.createdActivity).toLocaleString()}</TableCell>
                <TableCell>
                  <Box
                    component="span"
                    sx={{
                      padding: '4px 8px',
                      borderRadius: '4px',
                      color: 'black',
                      backgroundColor: 'yellow',
                      display: 'inline-block'
                    }}
                  >
                    processing
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderHistoryTransactions = () => {
    if (!historyTransaction || historyTransaction.length === 0) {
      return <Typography>No history transactions found.</Typography>;
    }
    return isMobile || isTablet ? (
      <Box sx={{ marginTop: 2 }}>
        {historyTransaction.map((transaction, index) => (
          <Box key={index} sx={{ marginBottom: 2, backgroundColor: '#1d1d1d', padding: 2, borderRadius: 1 }}>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>From:</strong> {truncateMiddle(transaction.from, isMobile ? 10 : 20)}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>To:</strong> {truncateMiddle(transaction.to, isMobile ? 10 : 20)}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>Type:</strong> {transaction.type}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>Divisa:</strong> {transaction.divisa}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>Amount:</strong> {transaction.amount}</Typography>
            <Typography variant="body1" sx={{ color: transaction.state === 'Success' ? 'white' : 'black', backgroundColor: transaction.state === 'Success' ? 'green' : 'red', display: 'inline-block', padding: '4px 8px', borderRadius: '4px' }}>{transaction.state}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}><strong>Date:</strong> {new Date(transaction.date).toLocaleString()}</Typography>
            <Typography variant="body1" sx={{ color: 'white' }}>
              <strong>TxId:</strong> {transaction.txId && transaction.txId !== '-' ? (
                <Tooltip title="Open in Ronin Explorer">
                  <IconButton size="small" onClick={() => window.open(`https://explorer.roninchain.com/tx/${transaction.txId}`, '_blank')}>
                    <OpenInNewIcon fontSize="small" sx={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              ) : 'N/A'}
            </Typography>
          </Box>
        ))}
      </Box>
    ) : (
      <TableContainer component={Paper} sx={{ marginTop: 2, backgroundColor: '#1e1e1e', color: 'white' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
          <Typography variant="h6" sx={{ color: 'white' }}>History Transactions</Typography>
          <Tooltip title="Search">
            <IconButton size="large" onClick={handleAddActivityClick}>
              <AddIcon fontSize="large" sx={{ color: 'yellow' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Table aria-label="history transactions table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'rgba(61, 128, 223, 1)' }}>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>From</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>To</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Divisa</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Amount</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>State</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>TxId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyTransaction.map((transaction, index) => (
              <TableRow key={index} sx={{ backgroundColor: '#1d1d1d' }}>
                <TableCell sx={{ color: 'white' }}>{truncateMiddle(transaction.from, 20)}</TableCell>
                <TableCell sx={{ color: 'white' }}>{truncateMiddle(transaction.to, 20)}</TableCell>
                <TableCell sx={{ color: 'white' }}>{transaction.type}</TableCell>
                <TableCell sx={{ color: 'white' }}>{transaction.divisa}</TableCell>
                <TableCell sx={{ color: 'white' }}>{transaction.amount}</TableCell>
                <TableCell>
                  <Box
                    component="span"
                    sx={{
                      padding: '4px 8px',
                      borderRadius: '4px',
                      color: transaction.state === 'Success' ? 'white' : 'black',
                      backgroundColor: transaction.state === 'Success' ? 'green' : 'red',
                      display: 'inline-block'
                    }}
                  >
                    {transaction.state}
                  </Box>
                </TableCell>
                <TableCell sx={{ color: 'white' }}>{new Date(transaction.date).toLocaleString()}</TableCell>
                <TableCell>
                  {transaction.txId && transaction.txId !== '-' ? (
                    <Tooltip title="Open in Ronin Explorer">
                      <IconButton size="small" onClick={() => window.open(`https://explorer.roninchain.com/tx/${transaction.txId}`, '_blank')}>
                        <OpenInNewIcon fontSize="small" sx={{ color: 'white' }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    'N/A'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleNotificationClose = () => {
    setNotification({ open: false, message: '', severity: 'success' });
  };

  return (
    <Box sx={{ padding: 2, margin: 0 }}>
      <Grid container spacing={2} sx={{ marginBottom: 3 }}>
        <Grid item xs={12} md={6}>
          {user && (
            <Card sx={{ backgroundColor: '#1e1e1e', color: 'white', padding: 2, height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h4" sx={{ fontFamily: 'serif' }}>
                  {user.username}
                </Typography>
                {user.accountVerified ? (
                  <CheckCircleIcon sx={{ color: 'green', fontSize: '2.5rem' }} />
                ) : (
                  <ErrorIcon sx={{ color: 'red', fontSize: '2.5rem' }} />
                )}
              </Box>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#1a237e',
                color: 'white',
                boxShadow: 3,
                borderRadius: 0,
                padding: '12px 24px',
                '&:hover': {
                  backgroundColor: '#0d47a1',
                },
              }}
              onClick={handleOpenDepositDialog}
            >
              Deposit
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#0d47a1',
                color: 'white',
                boxShadow: 3,
                borderRadius: 0,
                padding: '12px 24px',
                '&:hover': {
                  backgroundColor: '#1a237e',
                },
              }}
              onClick={handleOpenWithdrawDialog}
            >
              Withdraw
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2, position: 'relative', bgcolor: '#1e1e1e', border: '2px solid rgba(13, 25, 43, 1)' }}>
        <Typography variant="h6" sx={{ color: 'white' }}>User Info</Typography>
        {user && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: 'rgba(61, 128, 223, 1)', color: 'white', height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)', mb: 1 }}>
                    Referral Code
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{truncateMiddle(user._id, 20)}</Typography>
                    <Tooltip title="Copy to clipboard">
                      <IconButton size="small" onClick={() => copyToClipboard(user._id)}>
                        <FileCopyIcon fontSize="small" sx={{ color: 'white' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: 'rgba(61, 128, 223, 1)', color: 'white', height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)', mb: 1 }}>
                    Referral
                  </Typography>
                  <Typography>{(referrer && referrer.username) || 'Not Referred'}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: 'rgba(61, 128, 223, 1)', color: 'white', height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)', mb: 1 }}>
                    Address
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{truncateMiddle(user.addresses[0], 20)}</Typography>
                    <Tooltip title="Copy to clipboard">
                      <IconButton size="small" onClick={() => copyToClipboard(user.addresses[0])}>
                        <FileCopyIcon fontSize="small" sx={{ color: 'white' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open in Ronin Explorer">
                      <IconButton size="small" onClick={() => window.open(`https://explorer.roninchain.com/address/${user.addresses[0]}`, '_blank')}>
                        <OpenInNewIcon fontSize="small" sx={{ color: 'white' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ backgroundColor: 'rgba(61, 128, 223, 1)', color: 'white', height: '100%' }}>
                <CardContent>
                  <Typography variant="h6" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)', mb: 1 }}>
                    VipExpiredPixels
                  </Typography>
                  <Typography>{new Date(user.vipExpired).toLocaleString()}</Typography>
                </CardContent>
              </Card>
            </Grid>
                      {/* Nueva tarjeta para el link del referrer */}
          <Grid item xs={12} md={6}>
            <Card sx={{ backgroundColor: 'rgba(61, 128, 223, 1)', color: 'white', height: '100%' }}>
              <CardContent>
                <Typography variant="h6" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)', mb: 1 }}>
                  Link Referrel
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>
                    <a href={`https://pixels.roningamingtools.xyz/?ref=${user._id}`} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
                      https://pixels.roningamingtools.xyz/?ref={user._id}
                    </a>
                  </Typography>
                  <Tooltip title="Copy to clipboard">
                    <IconButton size="small" onClick={() => copyToClipboard(`https://pixels.roningamingtools.xyz/?ref=${user._id}`)}>
                      <FileCopyIcon fontSize="small" sx={{ color: 'white' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          </Grid>
            {!(referrer && referrer.username) && (
              <Grid item xs={12}>
                <TextField
                  label="Referrer"              
                  onChange={(e) => setReferrer(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="outlined"
                          onClick={handleAddReferrer}
                          sx={{
                            padding: '4px 16px',
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                            color: 'white',
                            backgroundColor: 'transparent',
                            borderRadius: 0,
                            fontSize: '1rem',
                            '&:hover': {
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                          }}
                        >
                          ADD
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    input: { color: '#ffffff' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#90caf9',
                      },
                      '&:hover fieldset': {
                        borderColor: '#64b5f6',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#42a5f5',
                      },
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Paper>
      {renderOrderWithdrawals()}
      {renderHistoryTransactions()}
      <Notification
        open={notification.open}
        handleClose={handleNotificationClose}
        message={notification.message}
        severity={notification.severity}
      />
      <Dialog open={openDepositDialog} onClose={handleCloseDepositDialog}>
        <DialogTitle>Deposit USD</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={depositAmount}
            onChange={handleInputChange}
            inputProps={{ step: '0.01' }} // Allow decimals
            sx={{
              input: { color: '#ffffff' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#90caf9',
                },
                '&:hover fieldset': {
                  borderColor: '#64b5f6',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#42a5f5',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDepositDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeposit} color="primary">
            {isRegistering ? <CircularProgress size={24} /> : 'Deposit'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openWithdrawDialog} onClose={handleCloseWithdrawDialog}>
        <DialogTitle>Withdraw USD</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={withdrawAmount}
            onChange={handleWithdrawInputChange}
            inputProps={{ step: '0.01' }} // Allow decimals
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setWithdrawAmount(user.matx);
                      console.log(`Setting max withdraw amount to ${user.matx}`);
                    }}
                    sx={{
                      padding: '4px 16px',
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                      color: 'white',
                      backgroundColor: 'transparent',
                      borderRadius: 0,
                      fontSize: '1rem',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  >
                    MAX
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{
              input: { color: '#ffffff' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#90caf9',
                },
                '&:hover fieldset': {
                  borderColor: '#64b5f6',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#42a5f5',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithdrawDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleWithdraw} color="primary">
            {isRegistering ? <CircularProgress size={24} /> : 'Withdraw'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;
