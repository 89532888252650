import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, CssBaseline, ThemeProvider, Typography , Link } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import MenuAccordion from './components/MenuAccordion';
import Profile from './components/Profile';
import Membership from './components/Membership';
import Market from './components/Market';
import OrdersList from './components/OrdersList';
import LoadingDots from './components/LoadingDots';
import WalletCard from './components/WalletCard';
import { WalletSDK } from '@roninnetwork/wallet-sdk';
import config from './components/config';
import Home from './components/Home';
import { GlobalStateProvider, useGlobalStateDispatch, useGlobalState } from './GlobalStateContext';
import TopBar from './components/TopBar';
import RoninWalletIcon from './assets/RoninWallet.png';
import MyOrder from './components/MyOrder';
import Top from './components/Top';
import Track from './components/Track';
import Register from './components/Register';
import Terms from './components/Terms';
import Notification from './components/Notification';
import webSocketService from './websocket';
import Selling from './components/market/Selling';
import DiscordCallback from './components/DiscordCallback';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#90caf9',
    },
    background: {
      default: '#121212',
      paper: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0bec5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1d1d',
          color: '#ffffff',
          '&.Mui-expanded': {
            margin: 'auto',
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1d1d',
          color: '#ffffff',
          '&.Mui-expanded': {
            minHeight: 48,
          },
          '&:hover': {
            backgroundColor: '#333333',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          },
        },
        expandIconWrapper: {
          color: '#ffffff',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#2c2c2c',
          color: '#ffffff',
        },
      },
    },
  },
});

const calculateDaysLeft = (membershipExpired) => {
  const currentDate = new Date();
  const expirationDate = new Date(membershipExpired);
  const timeDiff = expirationDate - currentDate;
  const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysLeft;
};

const AppContent = () => {
  const [walletAddress, setWalletAddress] = useState(localStorage.getItem('walletAddress') || '');
  const [isConnecting, setIsConnecting] = useState(false);
  const [menuOpen, setMenuOpen] = useState(window.innerWidth >= 768);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useGlobalStateDispatch();
  const state = useGlobalState();
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  const checkRoninInstalled = () => 'ronin' in window;

  const openRoninInstallationPage = () => {
    window.open('https://wallet.roninchain.com', '_blank');
  };

  const registerWallet = async (address) => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('ref') || "";
    console.log(referrer)
    try {
      const response = await fetch(`${config.API_BASE_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address, referral: referrer }),
      });
      const data = await response.json();
      if (data.message && data.message.includes('La wallet ya está registrada.')) {
        return 'alreadyRegistered';
      }
      return 'success';
    } catch (error) {
      console.error('Error registering wallet:', error);
      return 'error';
    }
  };

  const loginUser = async (address) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address }),
      });
      const data = await response.json();
      if(data.message)    
        if (data.message == 'La wallet proporcionada es incorrecta o no válida.') {
          await registerWallet(address);
        }
        if (data.message === 'Tu cuenta se encuentra disputa.') {         
          disconnectRoninWallet();
          setNotification({ open: true, message: 'Your account is in dispute.', severity: 'warning' });
          return 'Tu cuenta se encuentra disputa.'
        } else if (data.message === 'Tu cuenta se encuentra baneada.') {
          disconnectRoninWallet();
          setNotification({ open: true, message: 'Your account is banned.', severity: 'warning' });
          return 'Tu cuenta se encuentra baneada.'
        }
      if (data.token) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        
        dispatch({
          type: 'UPDATE_GLOBAL_STATE',
          payload: {
            user: data.user,
            token: data.token,
            orderWithdrawl: data.orderWithdrawl,
            historyTransaction: data.historyTransaction,
          },
        });
        
        dispatch({
          type: 'UPDATE_REFERRER', 
          payload: data.referrer, 
        });
  
        await webSocketService.startConnection(data.token);
        return 'success';
      }
    } catch (error) {
      console.error('Error logging in:', error);
      return 'error';
    }
  };
  const connectRoninWallet = async () => {
    setIsConnecting(true);
    if (!checkRoninInstalled()) {
      openRoninInstallationPage();
      setIsConnecting(false);
      return;
    }
  
    const sdk = new WalletSDK({
      dappMetadata: {
        url: window.location.origin,
        name: 'RoninGamingTools',
        description: 'This is a test dApp',
        icon: 'https://example-dapp/assets/logo.png',
      },
    });
  
    try {
      await sdk.connectInjected();
      const accounts = await sdk.requestAccounts();
      console.log(accounts.length > 0)
      if (accounts && accounts.length > 0) {
        const address = accounts[0];
        setWalletAddress(address);
        localStorage.setItem('walletAddress', address);
  
        const registrationResult = await registerWallet(address);
        if (typeof registrationResult === 'object' && registrationResult.message) {
  
          if (registrationResult.message === 'Tu cuenta se encuentra disputa.') {         
            disconnectRoninWallet();
            setNotification({ open: true, message: 'Your account is in dispute.', severity: 'warning' });
          } else if (registrationResult.message === 'Tu cuenta se encuentra baneada.') {
            disconnectRoninWallet();
            setNotification({ open: true, message: 'Your account is banned.', severity: 'warning' });
          } else {
            setNotification({ open: true, message: 'Error registering wallet: ' + registrationResult.message, severity: 'error' });
            setWalletAddress('');
            localStorage.removeItem('walletAddress');
          }
        } else if (registrationResult === 'alreadyRegistered') {
          console.log("pasa por el registrationresult")
          const loginResult = await loginUser(address);
          if(loginResult.message)
            if (loginResult.message === 'Tu cuenta se encuentra disputa.') {         
              disconnectRoninWallet();
              setNotification({ open: true, message: 'Your account is in dispute.', severity: 'warning' });
            } else if (loginResult.message === 'Tu cuenta se encuentra baneada.') {
              disconnectRoninWallet();
              setNotification({ open: true, message: 'Your account is banned.', severity: 'warning' });
            }
          }else {
          setNotification({ open: true, message: 'Error registering wallet', severity: 'error' });
          setWalletAddress('');
          localStorage.removeItem('walletAddress');
        }
      } else {
        setNotification({ open: true, message: 'No accounts found', severity: 'error' });
      }
    } catch (error) {
      console.error('Error connecting to Ronin Wallet:', error);
      setNotification({ open: true, message: 'Error connecting to Ronin Wallet', severity: 'error' });
      setWalletAddress('');
      localStorage.removeItem('walletAddress');
    } finally {
      setIsConnecting(false);
    }
  };

  const disconnectRoninWallet = async () => {
    try {
      const sdk = new WalletSDK();
      await sdk.disconnect();
      setWalletAddress('');
      localStorage.removeItem('walletAddress');
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      dispatch({ type: 'RESET_GLOBAL_STATE' });
      navigate('/');
    } catch (error) {
      console.error('Error disconnecting from Ronin Wallet:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token && walletAddress) {
      loginUser(walletAddress);
    }
  }, [walletAddress]);

  useEffect(() => {
    if (location.pathname !== window.location.pathname) {
      navigate(location.pathname);
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setMenuOpen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNotificationClose = () => {
    setNotification({ open: false, message: '', severity: 'success' });
  };

  useEffect(() => {
    const storedWalletAddress = localStorage.getItem('walletAddress');
    if (storedWalletAddress) {
      setWalletAddress(storedWalletAddress);
      const interval = setInterval(async () => {
        const status = await loginUser(storedWalletAddress);
        if (status === 'Tu cuenta se encuentra disputa.' || status === 'Tu cuenta se encuentra baneada.') {
          clearInterval(interval);
        }
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    } else {
      console.log("No se encontró ninguna dirección de billetera en localStorage.");
    }
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopBar onMenuClick={toggleMenu} userInfo={state.user} />
      <Box sx={{ display: 'flex', height: '100vh', bgcolor: 'background.default', color: 'text.primary', paddingTop: '64px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: menuOpen ? 250 : 0,
            bgcolor: '#1d1d1d',
            justifyContent: 'space-between',
            borderRight: '1px solid rgba(255, 255, 255, 0.12)',
            position: 'fixed',
            left: 0,
            top: '64px',
            bottom: 0,
            transition: 'width 0.3s',
          }}
        >
          {menuOpen && <MenuAccordion isConnecting={isConnecting} userInfo={state.user} />}
          {menuOpen && (
            <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 0, flexDirection: 'column' }}>
              {isConnecting ? (
                <LoadingDots />
              ) : walletAddress ? (
                <WalletCard
                  onDisconnect={disconnectRoninWallet}
                  calculateDaysLeft={calculateDaysLeft}
                  connectRoninWallet={connectRoninWallet} 
                />
              ) : (
                <Button
                  variant="contained"
                  onClick={connectRoninWallet}
                  sx={{ 
                    mt: 1, 
                    fontSize: '0.875rem', 
                    padding: '5px 10px', 
                    maxWidth: '200px',
                    background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  startIcon={<img src={RoninWalletIcon} alt="Ronin Wallet" style={{ width: 24, height: 24 }} />}
                >
                  Connect Wallet
                </Button>
              )}
    <Typography variant="body2" color="textSecondary">
      © 2024 Team
      {' | '}
      <Link
        href="https://wiki.roningamingtools.xyz/"
        color="inherit"
        underline="hover"
        target="_blank"
        rel="noopener noreferrer"
      >
        Whitepaper
      </Link>
    </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ flexGrow: 1, p: 3, marginLeft: menuOpen ? '250px' : '0', transition: 'margin-left 0.3s' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/market" element={<Market />} />
            <Route path="/orders" element={<OrdersList />} />
            <Route path="/top" element={<Top />} />
            <Route path="/register" element={<Register />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/create-order" element={<MyOrder />} />
            <Route path="/track-lands" element={<Track />} />
            <Route path="/auth/discord/callback" element={<DiscordCallback />} />
            <Route path="/market/:operationNumber" element={<Selling />} /> {/* Ruta para Selling */}
          </Routes>
        </Box>
      </Box>
      <Notification
        open={notification.open}
        handleClose={handleNotificationClose}
        message={notification.message}
        severity={notification.severity}
      />
    </ThemeProvider>
  );
};

const App = () => (
  <GlobalStateProvider>
    <AppContent />
  </GlobalStateProvider>
);

export default App;