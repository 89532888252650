import React from 'react';
import OrdersList from './OrdersList';
import { Box } from '@mui/material';

const Market = () => {
  return (
    <Box sx={{ p: 3}}>
      <OrdersList />
    </Box>
  );
};

export default Market;



