import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Badge, Box, Menu, MenuItem, Typography, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from 'react-router-dom';
import RoninLogo from '../assets/RoninGamingTools.png';
import { useGlobalState, useGlobalStateDispatch } from '../GlobalStateContext';

const TopBar = ({ onMenuClick }) => {
  const navigate = useNavigate();
  const { user, notification } = useGlobalState();
  const dispatch = useGlobalStateDispatch();
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [notificationDisplayed, setNotificationDisplayed] = useState(false);

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleNotificationAction = (operationNumber) => {
    navigate(`/market/${operationNumber}`);
    handleNotificationClose();
    setNotificationDisplayed(false);
  };

  const shouldShowNotification = notification && notification.state !== 'open';

  useEffect(() => {
    if (shouldShowNotification && !notificationDisplayed) {
      setNotificationAnchorEl(document.body);
      setNotificationDisplayed(true);
    } else if (!shouldShowNotification) {
      setNotificationAnchorEl(null);
      setNotificationDisplayed(false);
    }
  }, [notification, shouldShowNotification, notificationDisplayed]);

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'rgb(21, 32, 43)', background: 'linear-gradient(90deg, rgba(16, 20, 27, 0.043137254901960784) 0.00%, rgba(56, 121, 210, 0.7058823529411765) 11.11%, rgba(61, 128, 223, 0.792156862745098) 22.22%, rgba(61, 125, 221, 0.8) 33.33%, rgba(57, 120, 216, 0.7764705882352941) 44.44%, rgba(59, 123, 217, 0.7411764705882353) 55.56%, rgba(60, 123, 215, 0.7294117647058823) 66.67%, rgba(55, 118, 216, 0.7411764705882353) 77.78%, rgba(58, 119, 214, 0.7215686274509804) 88.89%, rgba(27, 36, 53, 0.12156862745098039) 100.00%)' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={handleLogoClick}>
              <img src={RoninLogo} alt="Ronin Gaming Tools" style={{ height: 50, marginRight: 10 }} />
            </Box>
          </Box>
          {user && (
            <>
              <IconButton color="inherit" onClick={handleNotificationClick}>
                <Badge badgeContent={shouldShowNotification ? 1 : 0} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                onClick={handleProfileClick}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
              >
                <AccountCircle />
              </IconButton>
            </>
          )}
        </Toolbar>
        <Menu
          anchorEl={notificationAnchorEl}
          open={Boolean(notificationAnchorEl)}
          onClose={handleNotificationClose}
          PaperProps={{
            style: {
              backgroundColor: 'rgb(21, 32, 43)', 
              background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
              color: 'white', 
              width: '300px',
              fontSize: '16px',
              maxHeight: '400px',
              marginTop: '10px',
              borderRadius: '8px'
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {shouldShowNotification ? (
            <MenuItem onClick={() => handleNotificationAction(notification.noperation)} sx={{ display: 'flex', alignItems: 'center' }}>
              <ShoppingCartIcon sx={{ marginRight: 1 }} />
              <Typography variant="body1" align="center">
                <Link href={`/market/${notification.noperation}`} color="inherit" underline="none">
                  Go to operation {notification.noperation}
                </Link>
              </Typography>
            </MenuItem>
          ) : (
            <MenuItem disabled sx={{ textAlign: 'center' }}>
              <Typography variant="body1">
                No notifications available
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </AppBar>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          width: 50,
          height: 50,
          borderRadius: '50%',
          backgroundColor: '#7289da',
          border: '3px solid white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1300, 
        }}
      >
     <Link
          href="https://discord.gg/22myPvuBng"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <img
            src="https://i.postimg.cc/tR6rsMrN/pngwing-com.png"
            alt="Discord"
            style={{
              width: '30px',
              height: '30px',
              objectFit: 'contain',
            }}
          />
        </Link>
      </Box>
    </>
  );
};

export default TopBar;























