import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box, IconButton } from '@mui/material';
import RoninIcon from '@mui/icons-material/AccountBalanceWallet';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MembershipIcon from '@mui/icons-material/CardMembership';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/CropSquare';
import CopyIcon from '@mui/icons-material/FileCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useGlobalState, useGlobalStateDispatch } from '../GlobalStateContext';
import RoninWalletIcon from '../assets/RoninWallet.png'; // Importar el icono de la billetera
import { useNavigate } from 'react-router-dom';
import config from './config'; // Asegúrate de importar el config para el endpoint

const WalletCard = ({ onDisconnect, calculateDaysLeft, connectRoninWallet }) => {
  const state = useGlobalState();
  const dispatch = useGlobalStateDispatch();
  const userInfo = state.user;
  const [isMinimized, setIsMinimized] = useState(false);
  const navigate = useNavigate();

  const handleToggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const truncateUsername = (username) => {
    if (username && username.length > 16) {
      return username.slice(0, 16) + '...';
    }
    return username || '';
  };

  const handleRefresh = async () => {
    if (!userInfo) return;

    try {
      const response = await fetch(`${config.API_BASE_URL}/updateInfoPixel`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ userId: userInfo.id }), // Ajusta el cuerpo según sea necesario
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Account update successful:', result);

        // Actualizar el estado global con los datos nuevos
        dispatch({
          type: 'UPDATE_GLOBAL_STATE',
          payload: {
            user: {
              ...userInfo,
              username: result.username || userInfo.username,
              vipExpired: result.vipExpired || userInfo.vipExpired,
              vipVerified: result.vipVerified || userInfo.vipVerified,
            },
            token: state.token,
            orderWithdrawl: state.orderWithdrawl,
            historyTransaction: state.historyTransaction,
          },
        });
      } else {
        const result = await response.json();
        console.error('Error updating account:', result.message);
      }
    } catch (error) {
      console.error('Error during update:', error);
    }
  };

  return (
    <Box sx={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'center', mb: 2 }}>
      <Card sx={{ 
        background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
        color: 'white', 
        borderRadius: '8px', 
        padding: '10px 20px', 
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)', 
        width: '90%', 
        border: '2px solid rgba(13, 25, 43, 1)',
        height: isMinimized ? '48px' : 'auto', // Ajustar altura cuando está minimizado
      }}>
        <CardContent sx={{ padding: '1px', '&:last-child': { paddingBottom: '10px' } }}>
          {!isMinimized && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
              <IconButton onClick={handleToggleMinimize} sx={{ color: 'white', padding: 0, fontSize: '0.875rem' }}>
                <MinimizeIcon fontSize="small" />
              </IconButton>
              <IconButton 
                onClick={userInfo ? handleProfileClick : () => { navigator.clipboard.writeText(userInfo?.shortAddresses?.[0] || '') }} 
                sx={{ color: 'white', padding: 0, fontSize: '0.875rem' }}
              >
                {userInfo ? <MaximizeIcon fontSize="small" /> : <CopyIcon fontSize="small" />}
              </IconButton>
              {!userInfo?.accountVerified && (
                <IconButton 
                  onClick={handleRefresh} 
                  sx={{ color: 'red', padding: 0, fontSize: '0.875rem' }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          )}
          {!isMinimized && userInfo && userInfo.username && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Typography variant="h6" sx={{ color: 'white', fontSize: '0.875rem' }}>
                  {truncateUsername(userInfo.username)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <RoninIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <Typography variant="body1" sx={{ color: 'white', fontSize: '0.875rem' }}>
                  {userInfo.shortAddresses[0]}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <MembershipIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <Typography variant="body1" sx={{ color: userInfo.membership.range === 'FREE' ? 'red' : '#32CD32', fontSize: '0.875rem', textTransform: 'uppercase' }}>
                  {userInfo.membership.range}
                </Typography>
                {userInfo.membership.range !== 'free' && (
                  <Typography variant="body1" sx={{ color: userInfo.membership.eternal ? 'white' : calculateDaysLeft(userInfo.membership.membershipExpired) <= 5 ? 'red' : '#32CD32', fontSize: '0.875rem', ml: 1 }}>
                    {userInfo.membership.eternal ? '' : `- ${calculateDaysLeft(userInfo.membership.membershipExpired)} DAYS`}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AttachMoneyIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                <Typography variant="body1" sx={{ color: 'white', fontSize: '0.875rem' }}>
                  {userInfo.matx.toLocaleString()} USD
                </Typography>
              </Box>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={onDisconnect} 
                fullWidth 
                sx={{ mt: 1, fontSize: '0.875rem', padding: '5px 10px' }}
              >
                Disconnect
              </Button>
            </>
          )}          
          {isMinimized && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography variant="h6" sx={{ color: 'white', fontSize: '1rem', display: 'flex', alignItems: 'center' }}>
                {userInfo ? truncateUsername(userInfo.username) : 'Wallet'}
              </Typography>
              <IconButton onClick={handleToggleMinimize} sx={{ color: 'white', padding: 0 }}>
                <MaximizeIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default WalletCard;
























