import * as signalR from '@microsoft/signalr';
import { useGlobalStateDispatch } from './GlobalStateContext';

class WebSocketService {
  constructor() {
    this.connection = null;
    this.isConnecting = false;
    this.connectionPromise = null;
    this.dispatch = null;
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  async startConnection(token) {
    if (!token) {
      console.log('No se proporcionó un token, omitiendo la conexión a SignalR.');
      return;
    }

    if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
      console.log('Ya está conectado a SignalR');
      return;
    }

    if (this.isConnecting) {
      console.log('Ya se está intentando conectar a SignalR');
      return this.connectionPromise;
    }

    this.isConnecting = true;
    this.connectionPromise = new Promise(async (resolve, reject) => {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl('https://pixels-tools-data.somee.com/MateriaXhub', {
          accessTokenFactory: () => token,
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();

      this.connection.onclose(async () => {
        console.log('Conexión cerrada, intentando reconectar...');
        await this.startConnection(token);
      });

      this.connection.on('SendNotification', (notification) => {
        console.log('Notificación recibida en el frontend:', notification);
        if (this.dispatch) {
          this.dispatch({
            type: 'UPDATE_NOTIFICATION',
            payload: notification,
          });
        }
      });

      this.connection.on('ordersView', (orders) => {
        console.log('Órdenes recibidas en el frontend:', orders);
        if (this.dispatch) {
          this.dispatch({
            type: 'UPDATE_ORDERS',
            payload: orders,
          });
        }
      });

      try {
        await this.connection.start();
        console.log('Conectado a SignalR');
        resolve();
      } catch (err) {
        console.log('Error conectando a SignalR:', err);
        reject(err);
      } finally {
        this.isConnecting = false;
      }
    });

    return this.connectionPromise;
  }

  async sendInitialInfo(userId) {
    try {
      console.log('Enviando información inicial para usuario:', userId);
      await this.connection.invoke('SendInitialInfoToClient', userId);
    } catch (err) {
      console.log('Error enviando información inicial:', err);
    }
  }

  async ensureConnection(token) {
    if (!this.connection || this.connection.state !== signalR.HubConnectionState.Connected) {
      await this.startConnection(token);
    }
  }

  async invoke(methodName, ...args) {
    const token = localStorage.getItem('authToken');
    await this.ensureConnection(token);

    if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) {
      return this.connection.invoke(methodName, ...args)
        .catch(err => console.log(`Error invocando ${methodName}:`, err));
    } else {
      console.log(`No se puede invocar ${methodName}, la conexión no está en estado 'Connected'.`);
    }
  }

  on(methodName, callback) {
    if (this.connection) {
      console.log(`Registrando método ${methodName}`);
      this.connection.on(methodName, callback);
    }
  }

  off(methodName, callback) {
    if (this.connection) {
      console.log(`Desregistrando método ${methodName}`);
      this.connection.off(methodName, callback);
    }
  }
}

const webSocketService = new WebSocketService();
export default webSocketService;









