import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Button, CircularProgress, Grid } from '@mui/material';
import config from './config'; // Actualización del import
import { useGlobalStateDispatch } from '../GlobalStateContext'; // Importar hook del contexto

const Membership = ({ onMembershipUpdate }) => {
  const [membershipData, setMembershipData] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useGlobalStateDispatch();

  const fetchMembershipData = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${config.API_BASE_URL}/Membership`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ membership: "", state: "" }),
      });
      const data = await response.json();
      console.log('Membership Data:', data);
      setMembershipData(data);

      // Despachar la acción para actualizar la membresía en el estado global
      dispatch({
        type: 'UPDATE_MEMBERSHIP',
        payload: {
          membership: data.membership,
          matx: data.matx,
        },
      });

      if (onMembershipUpdate) {
        onMembershipUpdate(data.membership);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching membership data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembershipData();
  }, []);

  const handleUpgrade = async (membership) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${config.API_BASE_URL}/Membership`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ membership, state: "upgrade" }),
      });
      const data = await response.json();
      console.log('Upgrade Data:', data);
      fetchMembershipData();
    } catch (error) {
      console.error('Error upgrading membership:', error);
    }
  };

  const handleExtend = async (membership) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${config.API_BASE_URL}/Membership`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ membership, state: "extend" }),
      });
      const data = await response.json();
      console.log('Extend Data:', data);
      fetchMembershipData();
    } catch (error) {
      console.error('Error extending membership:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!membershipData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">Failed to load membership data</Typography>
      </Box>
    );
  }

  const tierColors = {
    bronze: '#cd7f32',
    silver: '#c0c0c0',
    gold: '#ffd700',
    black: '#0ff',
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        {['bronze', 'silver', 'gold', 'black'].map((tier) => (
          <Grid item xs={12} sm={6} md={3} key={tier}>
            <Card sx={{ 
              background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
              color: 'white', 
              borderRadius: '8px', 
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)', 
              borderColor: tierColors[tier], 
              borderWidth: '2px', 
              borderStyle: 'solid' 
            }}>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1, textAlign: 'center', backgroundColor: '#1e1e1e', borderRadius: '8px', padding: '5px 0', color: 'white' }}>
                  {tier.charAt(0).toUpperCase() + tier.slice(1)}
                </Typography>
                <Box sx={{ mb: 2, textAlign: 'center' }}>
                  <Typography variant="body2" color="white">Create withdrawals</Typography>
                  <Typography variant="body2" color="white">Create orders</Typography>
                  <Typography variant="body2" color="white">Orders up to {tier === 'bronze' ? '500K' : tier === 'silver' ? '1M' : tier === 'gold' ? '2M' : '100M'} COINS</Typography>
                  <Typography variant="body2" color="white">Fee {tier === 'black' ? 'No' : '1%'}</Typography>
                </Box>
                {membershipData.update && (
                  <Box sx={{ mb: 2, textAlign: 'center' }}>
                    <Typography variant="body2" color="white">Price to Upgrade</Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>{membershipData.update[tier] === 0 ? '-' : membershipData.update[tier]} USD</Typography>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      disabled={membershipData.update[tier] === 0}
                      onClick={() => handleUpgrade(tier)}
                      sx={{ width: '100%', backgroundColor: '#1e1e1e', color: 'white', '&:hover': { backgroundColor: '#333333' } }}
                    >
                      Upgrade
                    </Button>
                  </Box>
                )}
                {membershipData.extend && (
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2" color="white">Price to Extend</Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>{membershipData.extend[tier] === 0 ? '-' : membershipData.extend[tier]} USD</Typography>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      disabled={membershipData.extend[tier] === 0 || membershipData.membership.range !== tier}
                      onClick={() => handleExtend(tier)}
                      sx={{ width: '100%', backgroundColor: '#1e1e1e', color: 'white', '&:hover': { backgroundColor: '#333333' } }}
                    >
                      Extend
                    </Button>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Membership;











