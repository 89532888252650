import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import MembershipIcon from '@mui/icons-material/CardMembership';
import P2PIcon from '@mui/icons-material/SwapHoriz';
import SearchIcon from '@mui/icons-material/Search';
import LeaderboardIcon from '@mui/icons-material/EmojiEvents';
import RegisterIcon from '@mui/icons-material/HowToReg';
import TermsIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import CreateOrderIcon from '@mui/icons-material/AddShoppingCart'; 

const MenuAccordion = ({ isConnecting, userInfo }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box>
      <List sx={{ padding: 0 }}>
        <ListItem 
          button 
          onClick={() => navigate('/')}
          sx={{ 
            background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
            color: 'white', 
            padding: '10px 15px', 
            marginBottom: '1px', 
            fontSize: '0.875rem' 
          }}
        >
          <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="HOME" />
        </ListItem>
      </List>
      {userInfo && (
        <>
          <List sx={{ padding: 0 }}>
            <ListItem 
              button 
              onClick={() => navigate('/membership')}
              sx={{ 
                background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
                color: 'white', 
                padding: '10px 15px', 
                marginBottom: '1px', 
                fontSize: '0.875rem' 
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
                <MembershipIcon />
              </ListItemIcon>
              <ListItemText primary="MEMBERSHIP" />
            </ListItem>
          </List>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ margin: 0, '&:before': { display: 'none' } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
                color: 'white', 
                padding: '0 15px', 
                minHeight: '40px !important' 
              }}
            >
              <Typography>MARKET</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: 'rgba(61, 128, 223, 1)', color: 'white', padding: 0 }}>
              <List sx={{ padding: 0 }}>
                <ListItem 
                  button 
                  onClick={() => navigate('/market')}
                  sx={{ 
                    background: 'rgba(61, 128, 223, 1)', 
                    color: 'white', 
                    padding: '10px 15px', 
                    marginBottom: '1px', 
                    fontSize: '0.875rem' 
                  }}
                >
                  <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
                    <P2PIcon />
                  </ListItemIcon>
                  <ListItemText primary="P2P" />
                </ListItem>
                {userInfo.membership.range !== 'free' && (
                  <ListItem 
                    button 
                    onClick={() => navigate('/create-order')}
                    sx={{ 
                      background: 'rgba(61, 128, 223, 1)', 
                      color: 'white', 
                      padding: '10px 15px', 
                      marginBottom: '1px', 
                      fontSize: '0.875rem' 
                    }}
                  >
                    <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
                      <CreateOrderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create Order" />
                  </ListItem>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ margin: 0, '&:before': { display: 'none' } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ 
            background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
            color: 'white', 
            padding: '0 15px', 
            minHeight: '40px !important' 
          }}
        >
          <Typography>TOOLS</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: 'rgba(61, 128, 223, 1)', color: 'white', padding: 0 }}>
          <List sx={{ padding: 0 }}>
                <ListItem 
                  button 
                  onClick={() => navigate('/track-lands')}
                  sx={{ 
                    background: 'rgba(61, 128, 223, 1)', 
                    color: 'white', 
                    padding: '10px 15px', 
                    marginBottom: '1px', 
                    fontSize: '0.875rem' 
                  }}
                >
                  <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
                  <SearchIcon />
                  </ListItemIcon>
                  <ListItemText primary="Track-Land" />
                </ListItem>
              </List>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ margin: 0, '&:before': { display: 'none' } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ 
            background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)', 
            color: 'white', 
            padding: '0 15px', 
            minHeight: '40px !important' 
          }}
        >
          <Typography>LEADERBOARD</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: 'rgba(61, 128, 223, 1)', color: 'white', padding: 0 }}>
          <List sx={{ padding: 0 }}>
            <ListItem 
              button 
              onClick={() => navigate('/top')}
              sx={{ 
                background: 'rgba(61, 128, 223, 1)', 
                color: 'white', 
                padding: '10px 15px', 
                marginBottom: '1px', 
                fontSize: '0.875rem' 
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
                <LeaderboardIcon />
              </ListItemIcon>
              <ListItemText primary="TOP" />
            </ListItem>
            <ListItem 
              button 
              onClick={() => navigate('/register')}
              sx={{ 
                background: 'rgba(61, 128, 223, 1)', 
                color: 'white', 
                padding: '10px 15px', 
                marginBottom: '1px', 
                fontSize: '0.875rem' 
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
                <RegisterIcon />
              </ListItemIcon>
              <ListItemText primary="REGISTER" />
            </ListItem>
            <ListItem 
              button 
              onClick={() => navigate('/terms')}
              sx={{ 
                background: 'rgba(61, 128, 223, 1)', 
                color: 'white', 
                padding: '10px 15px', 
                marginBottom: '1px', 
                fontSize: '0.875rem' 
              }}
            >
              <ListItemIcon sx={{ color: 'white', minWidth: 32 }}>
                <TermsIcon />
              </ListItemIcon>
              <ListItemText primary="TERMS" />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MenuAccordion;












