import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, Grid, Skeleton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import webSocketService from '../websocket';
import { useGlobalState, useGlobalStateDispatch } from '../GlobalStateContext';
import Notification from './Notification';
import config from './config';

const OrdersList = () => {
  const [orders, setOrders] = useState({ countRoom: 0, buy: [], sell: [] });
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState('');
  const [calculatedValue, setCalculatedValue] = useState(0);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const { user, token, orders: globalOrders } = useGlobalState();
  const dispatch = useGlobalStateDispatch();
  const navigate = useNavigate();
  const hasRefreshed = useRef(false);

  useEffect(() => {
    webSocketService.setDispatch(dispatch);

    const fetchStocks = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/traderPack/list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        });
        const data = await response.json();
        setStocks(data.allLevels);
      } catch (error) {
        console.error('Error fetching stocks:', error);
      }
    };

    const startWebSocketConnection = async () => {
      try {
        await webSocketService.startConnection(token);
        webSocketService.on('ordersView', (newOrders) => {
          dispatch({
            type: 'UPDATE_ORDERS',
            payload: newOrders,
          });

          const userOrder = user?.myOrder;
          if (userOrder) {
            const orderExists = newOrders.buy.concat(newOrders.sell).some(order => order.operationNumber === userOrder.noperation);
            if (!orderExists && !hasRefreshed.current) {
              dispatch({
                type: 'UPDATE_NOTIFICATION',
                payload: { noperation: userOrder.noperation, message: 'Go to the order' },
              });
              hasRefreshed.current = true;
            }
          }
        });

        webSocketService.on('SendNotification', (notification) => {
          dispatch({
            type: 'UPDATE_NOTIFICATION',
            payload: notification,
          });
        });

        if (user && user._id) {
          await webSocketService.invoke('SendInitialInfoToClient', user._id);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error starting WebSocket connection:', error);
        setLoading(false);
      }
    };

    if (token) {
      fetchStocks();
      startWebSocketConnection();
    } else {
      setLoading(false);
    }

    return () => {
      webSocketService.off('ordersView');
      webSocketService.off('SendNotification');
    };
  }, [user, token, dispatch]);

  useEffect(() => {
    if (globalOrders) {
      setOrders(globalOrders);
      setLoading(false);
    }
  }, [globalOrders]);

  const refreshUserState = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/Refresh`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data) {
        dispatch({
          type: 'UPDATE_GLOBAL_STATE',
          payload: {
            user: data.user,
            token: data.token,
            orderWithdrawl: data.orderWithdrawl,
            historyTransaction: data.historyTransaction,
          },
        });
        setNotification({ open: true, message: 'User state refreshed.', severity: 'success' });
      }
    } catch (error) {
      console.error('Error actualizando el estado del usuario:', error);
      setNotification({ open: true, message: 'Error refreshing user state.', severity: 'error' });
    }
  };

  const handleStockChange = (event, order) => {
    const stock = event.target.value;
    setSelectedStock(stock);
    const pricePerUnit = order.price / order.stock;
    const value = stock * pricePerUnit;
    setCalculatedValue(value.toFixed(2));
  };

  const handleBuyOrSell = async (operationNumber) => {
    if (!selectedStock) {
      setNotification({ open: true, message: 'Please select a stock level.', severity: 'error' });
      return;
    }

    const body = {
      nOperation: operationNumber,
      stock: selectedStock,
      address: user.addresses[0],
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/order/join`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        navigate(`/market/${operationNumber}`);
      } else {
        const errorData = await response.json();
        setNotification({ open: true, message: errorData.message || 'Failed to join order.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error joining order:', error);
      setNotification({ open: true, message: 'Error joining order.', severity: 'error' });
    }
  };

  const renderOrder = (order) => {
    const totalOrders = order.transactionFailed + order.transactionSuccess;
    const successPercentage = totalOrders > 0 ? ((order.transactionSuccess / totalOrders) * 100).toFixed(2) : '0.00';

    const filteredStocks = stocks.filter(stock => stock <= order.stock);

    return (
      <Box
        key={order.operationNumber}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 2,
          background: 'linear-gradient(270deg, rgba(61, 128, 223, 1) 0%, #121212 100%)',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.7)',
          padding: '20px',
          borderRadius: 2,
          textAlign: 'center',
          width: '100%',
          maxWidth: '350px',
          margin: '10px',
        }}
      >
        <Typography variant="h6" sx={{ color: '#90caf9' }}>{order.username}</Typography>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          {`${totalOrders} orders - ${successPercentage}%`}
        </Typography>
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Typography variant="h6" sx={{ color: 'white' }}>{`${order.stock.toLocaleString()} COINS`}</Typography>
          <Typography variant="h6" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
            {`${order.price} USD`}
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Stock</InputLabel>
            <Select
              value={selectedStock}
              onChange={(event) => handleStockChange(event, order)}
              label="Stock"
            >
              {filteredStocks.map(stock => (
                <MenuItem key={stock} value={stock}>
                  {stock.toLocaleString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body1" sx={{ mt: 2, color: 'white' }}>
            Value: {calculatedValue} USD
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              bgcolor: order.type === 'buy' ? '#4caf50' : '#f44336',
              color: 'white',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.7)',
              mt: 2,
              '&:hover': {
                backgroundColor: order.type === 'buy' ? '#388e3c' : '#d32f2f',
              },
              width: '100%',
              maxWidth: '200px',
            }}
            onClick={() => handleBuyOrSell(order.operationNumber)}
            disabled={order.username === user.username}
          >
            {order.type === 'buy' ? 'SELL COIN' : 'BUY COIN'}
          </Button>
        </Box>
      </Box>
    );
  };

  const renderSkeletonOrder = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
        bgcolor: '#1e1e1e',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
        padding: '20px',
        borderRadius: 2,
        textAlign: 'center',
        width: '100%',
        maxWidth: '350px',
        margin: '10px',
      }}
    >
      <Skeleton variant="text" width="80%" sx={{ bgcolor: '#2e2e2e' }} />
      <Skeleton variant="text" width="60%" sx={{ bgcolor: '#2e2e2e' }} />
      <Box sx={{ mt: 1, width: '100%', textAlign: 'center' }}>
        <Skeleton variant="text" width="80%" sx={{ bgcolor: '#2e2e2e' }} />
        <Skeleton variant="text" width="70%" sx={{ bgcolor: '#2e2e2e' }} />
      </Box>
      <Skeleton variant="rectangular" width="80%" height={40} sx={{ bgcolor: '#2e2e2e', mt: 2 }} />
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button variant="text" onClick={() => setTabValue(0)} sx={{ color: tabValue === 0 ? '#90caf9' : 'white' }}>
            BUY COIN
          </Button>
          <Button variant="text" onClick={() => setTabValue(1)} sx={{ color: tabValue === 1 ? '#90caf9' : 'white' }}>
            SELL COIN
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {loading && (
            <Grid container spacing={2} justifyContent="center">
              {Array.from(new Array(6)).map((_, index) => (
                <Grid item key={index}>
                  {renderSkeletonOrder()}
                </Grid>
              ))}
            </Grid>
          )}
          {!loading && orders.sell.length === 0 && tabValue === 0 && (
            <Typography variant="h6" sx={{ color: 'white' }}>No buy orders available</Typography>
          )}
          {!loading && orders.buy.length === 0 && tabValue === 1 && (
            <Typography variant="h6" sx={{ color: 'white' }}>No sell orders available</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            {!loading && tabValue === 0 && orders.sell.map(renderOrder)}
            {!loading && tabValue === 1 && orders.buy.map(renderOrder)}
          </Grid>
        </Grid>
      </Grid>
      <Notification
        open={notification.open}
        handleClose={() => setNotification({ open: false, message: '', severity: 'success' })}
        message={notification.message}
        severity={notification.severity}
      />
    </Box>
  );
};

export default OrdersList;









